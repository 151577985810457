'use strict';

import AbstractEndpoint from './abstract.endpoint';
import ErrorResponse from '../../../entities/response/error.response';
import ItemResponse from '../../../entities/response/item.response';
import WishlistEntity from '../../../entities/game/wishlist.entity';
import CollectionResponse from '../../../entities/response/collection.response';

const BASE_URL = 'wishlist';

export default class WishlistEndpoint extends AbstractEndpoint
{
    add(wishlist) {

        return new Promise((resolve, reject) => {

            this.client
                .post(BASE_URL, wishlist)
                .then(response => {
                    resolve(new ItemResponse(response.status, WishlistEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    get(id) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL + '/' + id)
                .then(response => {
                    resolve(new ItemResponse(response.status, WishlistEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    update(id, wishlist) {

        wishlist.priority = parseInt(wishlist.priority);

        return new Promise((resolve, reject) => {

            this.client
                .put(BASE_URL + '/' + id, wishlist)
                .then(response => {
                    resolve(new ItemResponse(response.status, WishlistEntity.fromObject(response.data.data)));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    remove(id) {

        return new Promise((resolve, reject) => {

            this.client
                .delete(BASE_URL + '/' + id)
                .then(() => {
                    resolve(true);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }

    list(params) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL, { params })
                .then(response => {

                    const collection = new CollectionResponse(response.status, response.data.data.pagination);

                    response.data.data.items.map(item => {
                        collection.add(WishlistEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response ? error.response.status : 499));
                })
        });
    }
}