import axios from 'axios';
import headerService from '../../auth/header.service';

export default class AbstractEndpoint
{
    constructor()
    {
        const client = axios.create({
            baseURL: process.env.VUE_APP_API_URL
        });

        Object.assign(client.defaults.headers.common, headerService());

        this.client = client;
    }
}