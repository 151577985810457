<template>
    <vee-form v-slot="{ errors }" ref="form" v-on:submit="search" v-bind:validation-schema="schema">
        <ul class="form">
            <li v-if="services.length > 1" class="form-group">
                <select id="input-service" required="required" class="input-label" title="" v-model="model.id">
                    <option v-for="item in services" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </select>
                <label for="input-service">Service</label>
            </li>
            <li class="form-group">
                <select id="input-platform" required="required" v-model="model.platform" class="input-label" title="">
                    <option v-for="item in platforms" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </select>
                <label for="input-platform">Platform</label>
            </li>
            <li class="form-group">
                <vee-field id="input-title" name="title" type="text" v-model.trim="model.title" class="input-label" autofocus required="required" title="" v-bind:class="{error: errors.title}" />
                <label for="input-title">Search title</label>
                <vee-error-message name="title" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>
            <li class="form-group align-right">
                <input value="Search" type="button" v-on:click="search" class="btn">
            </li>
        </ul>
    </vee-form>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { configure } from 'vee-validate';
import APIService from '../../../services/api/api.service';
import * as yup from 'yup';

export default {

    emits: ['loading', 'search'],

    components: {

        'vee-form': require('vee-validate').Form,
        'vee-field': require('vee-validate').Field,
        'vee-error-message': require('vee-validate').ErrorMessage,
    },

    setup(props, {emit}) {

        configure({
            validateOnBlur: false
        });

        const schema = yup.object().shape({
            title: yup
                .string()
                .max(100, 'Maximum length of 100 characters')
                .min(2, 'Minimum length of 2 characters')
                .required('Title is required'),
        });

        const store = useStore();
        const api = new APIService();
        const form = ref();
        const results = ref({title: null, platform: null, serviceId: null});

        const model = ref({
            serviceId: 1,
            title: '',
            platform: null
        });

        const services = computed(() => store.state.services.items);
        const platforms = computed(() => store.state.platforms.items);

        const search = () => {

            form.value.validate().then(validation => {

                if(false === validation.valid) {
                    return;
                }

                //Check if results are already known. If so, no need to make an extra API request.
                if(results.value.title === model.value.title && results.value.platform === model.value.platform && results.value.serviceId === model.value.serviceId) {

                    emit('search', results.value);
                    return;
                }

                let id = 'games-search';

                emit('loading', id);

                api.service.search(model.value.serviceId, model.value.title, model.value.platform)
                    .then(collection => {

                        results.value = {
                            serviceId: model.value.serviceId,
                            platform: model.value.platform,
                            items: collection.items,
                            title: model.value.title,
                        };

                        emit('search', results.value)
                    })
                    .catch(error => {

                        if(401 === error.status) {
                            store.dispatch('account/logout');
                        }
                    })
                    .finally(() => {
                        emit('loading', id);
                    });
            });
        }

        return {
            model,
            services,
            platforms,
            schema,
            form,
            search,
        }
    }
};
</script>