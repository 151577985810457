'use strict';

export class DateTimeEntity
{
    constructor(date)
    {
        this.date = date;
    }

    static fromTimestamp(timestamp)
    {
        const date = new Date(timestamp);
        return new this(date);
    }
}