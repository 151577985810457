<template>
    <div class="notifications">
        <div v-for="(notification, index) in notifications" v-bind:key="index" class="notification active" v-bind:class="notification.type">
            <div class="border"></div>
            <div class="content">
                <div class="type-icon">
                    <span v-bind:class="'icon-' + notification.type"></span>
                </div>
                <div class="message">
                    <h3>{{ notification.title }}</h3>
                    <p>{{ notification.message }}</p>
                </div>
                <div class="progress">
                    <div class="bar"></div>
                </div>
            </div>
            <div class="close" v-on:click="notification.enabled = !notification.enabled">Close</div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {

    setup() {

        const store = useStore();

        const notifications = computed(() => {
            return store.state.notifications.items.filter(message => message.enabled);
        });

        return {
            notifications
        }
    }
}
</script>

<style scoped>
.notifications {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column-reverse;
    z-index: 10;
}
.notification.active {
    animation: fadeInDown;
    animation-duration: 4.9s;
    animation-fill-mode: both;
}
.notification.active .progress .bar {
    width: 100%;
    animation: progress;
    animation-duration: 4s;
    animation-fill-mode: both;
    animation-delay: 0.5s;
    animation-timing-function: linear;
}
.notification {
    position: relative;
    height: 90px;
    width: 400px;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    display: flex;

    margin-top: 5px;
    background: #fff;
    border-radius: 5px;
}
.notification .border {
    width: 10px;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.notification .content {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    width: 80%;
}
.notification .message {
    padding: 0 3%;
}
.notification .type-icon {
    margin: 0 3%;
}
.notification .close {
    text-transform: capitalize;
    color: #555555;
    width: 20%;
    border-left: 1px solid #ededed;
    height: 100%;
    line-height: 90px;
    text-align: center;
    cursor: pointer;
    user-select: none;
}
.notification .close:hover {
    background: #f8f8f8;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}
.notification h3 {
    padding: 0;
    margin-bottom: 1px;
    font-family: "poppins-semi-bold", serif;
}
.notification p {
    line-height: 1.3;
}
.notification .progress {
    height: 3px;
    right: 0;
    bottom: 0;
    position: absolute;
    left: 0;
}
.notification .progress .bar {
    background: var(--theme-color);
    width: 0;
    height: 100%;
}

/* Success notification */
.notification.success .border {
    background: #4ac860;
}
.notification.success .type-icon span {
    color: #4ac860;
    font-size: 30px;
}

/* Info notification */
.notification.info .border {
    background: #007bff;
}
.notification.info .type-icon span {
    color: #007bff;
    font-size: 30px;
}

/* Warning notification */
.notification.warning .border {
    background: #e8b900;
}
.notification.warning .type-icon span {
    color: #e8b900;
    font-size: 30px;
}

/* Error notification */
.notification.error .border {
    background: #fc5f60;
}
.notification.error .type-icon span {
    color: #fc5f60;
    font-size: 30px;
}

@media screen and (max-width: 480px) {
    .notifications {
        right: 0; width: 100%; padding: 0 2%;
    }
    .notification {
        width: 100%;
    }
}
</style>

