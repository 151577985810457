'use strict';

export default class FranchiseEntity
{
    constructor(id, title, slug)
    {
        this.id = id;
        this.title = title;
        this.slug = slug;
    }

    static fromObject(data)
    {
        return new this(data.id, data.title, data.slug);
    }
}