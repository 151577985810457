'use strict';

import ServiceGameEntity from '../../../entities/service-game/game.entity';
import AbstractEndpoint from './abstract.endpoint';
import ErrorResponse from '../../../entities/response/error.response';
import CollectionResponse from '../../../entities/response/collection.response';

const BASE_URL = 'service/';

export default class GameEndpoint extends AbstractEndpoint
{
    search(serviceId, title, platformId) {

        return new Promise((resolve, reject) => {

            this.client
                .get(BASE_URL + serviceId + '/search', { params: { query: title, 'platforms[]' : platformId } })
                .then(response => {

                    const collection = new CollectionResponse();

                    response.data.data?.map(item => {
                        collection.add(ServiceGameEntity.fromObject(item));
                    });

                    resolve(collection);
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }
}