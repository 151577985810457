'use strict';

export default class ImageEntity
{
    constructor(url, extension, name)
    {
        this.url = url;
        this.extension = extension;
        this.name = name;
    }

    static fromObject(data)
    {
        return new this(process.env.VUE_APP_API_DOMAIN + data.url, data.extension, data.name);
    }
}