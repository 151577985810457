<template>
    <div class="background"></div>
    <div class="login-box">
        <div class="box">
            <div class="title">
                <h2>Login</h2>
            </div>
            <div class="content">
                <vee-form v-slot="{ errors }" v-on:submit="login" ref="form" v-bind:validation-schema="schema" class="form">

                    <div class="form-group">
                        <vee-field id="input-username" name="username" type="text" class="input-label" v-bind:class="{error: errors.username}" required="required" title="" autofocus="autofocus" />
                        <label for="input-username">Username</label>
                        <vee-error-message name="username" v-slot="{ message }">
                            <span class="active error-icon">!</span>
                            <span class="active error-text">{{ message }}</span>
                        </vee-error-message>
                    </div>

                    <div class="form-group">
                        <vee-field id="input-password" name="password" type="password" class="input-label" v-bind:class="{error: errors.password}" required="required" title="" />
                        <label for="input-password">Password</label>
                        <vee-error-message name="password" v-slot="{ message }">
                            <span class="active error-icon">!</span>
                            <span class="active error-text">{{ message }}</span>
                        </vee-error-message>
                    </div>

                    <div class="form-group">
                        <button class="btn">Login</button>
                    </div>

                </vee-form>
            </div>
        </div>
    </div>

</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import * as yup from 'yup';
import APIService from '../../services/api/api.service';
import {configure} from 'vee-validate';

export default {

    emits: ['loading'],

    components: {

        'vee-form': require('vee-validate').Form,
        'vee-field': require('vee-validate').Field,
        'vee-error-message': require('vee-validate').ErrorMessage,
    },

    setup(props, { emit }) {

        configure({
            validateOnBlur: false
        });

        const store = useStore();
        const api = new APIService();
        const form = ref();

        const schema = yup.object().shape({
            username: yup.string().required('Username is required'),
            password: yup.string().required('Password is required'),
        });

        const login = user => {

            let id = 'login';

            emit('loading', id)

            api.account.authenticate(user.username, user.password)
                .then(response => {
                    emit('loading', id);
                    store.dispatch('account/login', response.item)
                })
                .catch(() => {
                    emit('loading', id);
                    form.value.setFieldError('username', 'Wrong username or password');
                });
        }

        return {
            loading: false,
            schema,
            login,
            form,
        };
    },
};
</script>

<style scoped>
.background {
    background: #333 url('../../../public/images/bg.png');
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

/* Box */
.login-box {
    max-width: 700px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 3%;
}
.box {
    box-shadow: 0 0 20px rgba(0,0,0,.3);
    background: #fff;
    position: relative;
    min-width: 0;
}
.box .title {
    padding: 15px 20px;
    background: var(--theme-color);
    border-bottom: 1px solid #ccc;
}
.box .title h2 {
    font-size: 18px;
    color: #fff;
}
.box .content {
    padding: 20px;
}
.box .content .scroll {
    overflow-y: auto; width: 100%;
}
.box .content p {
    margin-bottom: 10px;
}
</style>
