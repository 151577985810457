'use strict';

export default class PropertyEntity
{
    constructor(id, title, slug)
    {
        this.id = parseInt(id);
        this.title = title;
        this.slug = slug;
    }

    static fromObject(data)
    {
        return new this(data.id, data.title, data.slug);
    }
}