<template>

    <div v-bind:class="{'loading' : loading.enabled.value, 'scrollbar': loading.scrollbar.value}">
        <router-view v-if="account.loggedIn.value" v-on:loading="loading.update"></router-view>
        <cmp-login v-else v-on:loading="loading.update"></cmp-login>
    </div>

    <cmp-loader v-if="loading.enabled.value"></cmp-loader>
    <cmp-notifications></cmp-notifications>
</template>

<script>
import { computed, provide, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ConfigService from './services/config/config.service';
import JWTService from './services/auth/jwt.service';

export default {

    components: {
        'cmp-login': require('./views/account/login-cmp').default,
        'cmp-loader': require('./components/loader-cmp.vue').default,
        'cmp-notifications': require('./components/notifications-cmp.vue').default,
    },

    setup() {

        const configService = new ConfigService();
        const store = useStore();
        const account = {

            logoff() {
                store.dispatch('account/logout');
            },

            loggedIn: computed(() => {
                return store.state.account.status.loggedIn;
            }),

            user() {
                return JWTService.parse()
            }
        }

        const loading = {

            scrollbar: ref(false),
            queue: ref({}),

            enabled: computed(() => {
                return Object.keys(loading.queue.value).length > 0;
            }),

            update(enabled) {

                loading.scrollbar.value = document.body.scrollHeight > window.innerHeight;

                if(!loading.queue.value[enabled]) {
                    loading.queue.value[enabled] = true;
                    return;
                }

                delete loading.queue.value[enabled];
            }
        }

        watch(account.loggedIn, () => {

            let id = 'account-status';

            if(!account.loggedIn.value) {
                return;
            }

            loading.update(id);

            configService.load().finally(() => {
                loading.update(id);
            });
        }, {immediate: true});

        provide('account', account);

        return {
            loading,
            account
        }
    }
}
</script>