<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">

            <div class="breadcrumb">
                <router-link v-bind:to="{name: 'game.overview'}">Home</router-link> / Add game
            </div>

            <!-- Wizard -->
            <ul class="wizard">
                <li v-bind:class="{'done-50' : slide === 0, 'done-100' : slide > 0}">
                    <a v-on:click="navigation.game()">
                        <span>1</span>
                        <p>Find</p>
                    </a>
                </li>
                <li v-bind:class="{'done-50' : slide > 0, 'done-100' : slide > 1}">
                    <a v-if="items.length > 0" v-on:click="navigation.results()">
                        <span>2</span>
                        <p>Select</p>
                    </a>
                    <template v-else>
                        <span>2</span>
                        <p>Select</p>
                    </template>
                </li>
                <li v-bind:class="{'done-50' : slide > 1, 'done-100' : slide > 2}">
                    <a v-if="model.service.service_identifier" v-on:click="navigation.medium()">
                        <span>3</span>
                        <p>Save</p>
                    </a>
                    <template v-else>
                        <span>3</span>
                        <p>Save</p>
                    </template>
                </li>
            </ul>

            <!-- Search game -->
            <div v-show="slide === 0" class="slide">
                <cmp-search-form v-on:search="search" v-on:loading="loading"></cmp-search-form>
            </div>

            <!-- Select game -->
            <div v-show="slide === 1" class="slide">
                <cmp-results v-bind:items="items" v-on:select="select"></cmp-results>
                <ul class="form bottom-fixed">
                    <li class="align-right">
                        <input value="Next" v-bind:disabled="!model.service.service_identifier" v-on:click="navigation.medium" type="button" class="btn">
                    </li>
                </ul>
            </div>

            <!-- Save game to collection or wishlist -->
            <div v-show="slide === 2" class="slide">
                <ul class="form">
                    <li class="form-group">
                        <select id="input-save-to" required="required" class="input-label" title="" v-model="model.type">
                            <option v-bind:value="OWNER_TYPE_COLLECTION">Collection</option>
                            <option v-bind:value="OWNER_TYPE_WISHLIST">Wishlist</option>
                        </select>
                        <label for="input-save-to">Save to</label>
                    </li>
                </ul>
                <br>
                <cmp-medium-form v-show="model.type === OWNER_TYPE_COLLECTION" v-bind:platform="model.service.platform" v-bind:title="model.service.title" v-on:save="save.collection"></cmp-medium-form>
                <cmp-wishlist-form v-show="model.type === OWNER_TYPE_WISHLIST" v-bind:platform="model.service.platform" v-bind:title="model.service.title" v-on:save="save.wishlist"></cmp-wishlist-form>
            </div>
        </div>
    </div>

    <cmp-footer></cmp-footer>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate } from 'vue-router';
import { OWNER_TYPES, OWNER_TYPE_WISHLIST, OWNER_TYPE_COLLECTION } from '../../constants';
import APIService from '../../services/api/api.service';
import NotificationEntity from '../../entities/notification.entity.js';

export default {

    emits: ['loading'],

    components: {

        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
        'cmp-search-form': require('../../components/game/add/search-cmp.vue').default,
        'cmp-results': require('../../components/game/add/results-cmp.vue').default,
        'cmp-medium-form': require('../../components/game/medium-cmp').default,
        'cmp-wishlist-form': require('../../components/game/wishlist-cmp').default,
    },

    setup(props, {emit}) {

        const store = useStore();
        const slide = ref(2);
        const api = new APIService();
        const items = ref([]);

        const model = ref({
            service: {
                id: 1,
                service_identifier: null,
                platform: null,
                title: null
            },
            type: OWNER_TYPE_COLLECTION,
            gameMedia: []
        });

        const loading = state => {
            emit('loading', 'foo')
        }

        const navigation = {
            game() {

                history.replaceState(history.state, '', location.origin + location.pathname + '#game');
                slide.value = 0;
            },

            results() {

                history.replaceState(history.state, '', location.origin + location.pathname + '#results');
                slide.value = 1;
            },

            medium() {

                history.replaceState(history.state, '', location.origin + location.pathname + '#medium');
                slide.value = 2;
            },

            guard(hash) {

                if('#medium' === hash && 0 === items.value.length) {
                    navigation.game();
                    return;
                }

                if('#medium' === hash && null === model.value.service.service_identifier) {
                    navigation.results();
                    return;
                }

                if('#results' === hash && 0 === items.value.length) {
                    navigation.game();
                    return;
                }

                if(location.hash !== hash) {
                    return;
                }

                switch (hash) {
                    case '#medium': navigation.medium(); break;
                    case '#results': navigation.results(); break;
                    case '#game': navigation.game(); break;
                }
            }
        }

        const select = item => {
            model.value.service.service_identifier = item.id;
            model.value.service.title = item.title;
        }

        const search = async data => {

            if(0 === data.items.length) {

                await store.dispatch('notifications/add', new NotificationEntity('Info', 'info', 'There where no results matching the search criteria.'));
                return;
            }

            model.value.service.id = data.serviceId;
            model.value.service.platform = data.platform;
            items.value = data.items;
            navigation.results();
        }

        const save = {

            collection: medium => {

                let id = 'game-save';

                emit('loading', id);

                api.game.add(model.value.service.id, model.value.service.service_identifier)
                    .then(async response => {

                        medium.game = response.item.id;
                        await api.gameMedium.add(medium);
                        await store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully added game to the collection.'));
                        navigation.game();
                    })
                    .catch(error => {

                        if (401 === error.status) {
                            store.dispatch('account/logout');
                            return;
                        }

                        store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error has occurred.'));
                    })
                    .finally(() => {
                        emit('loading', id);
                    });
            },

            wishlist: wishlist => {

                let id = 'wishlist-add';

                emit('loading', id);

                api.game.add(model.value.service.id, model.value.service.service_identifier)
                    .then(async response => {

                        wishlist.game = response.item.id;
                        wishlist.priority = parseInt(wishlist.priority);
                        wishlist.service = parseInt(model.value.service.id);
                        wishlist.service_game_identifier = model.value.service.service_identifier.toString();

                        await api.wishlist.add(wishlist);
                        await store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully added game to the wishlist.'));
                        navigation.game();
                    })
                    .catch(error => {

                        if (401 === error.status) {
                            store.dispatch('account/logout');
                            return;
                        }

                        store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error has occurred.'));
                    })
                    .finally(() => {
                        emit('loading', id);
                    });
            }
        }

        onBeforeRouteUpdate(to => {
            navigation.guard(to.hash);
        })

        navigation.game();

        return {
            model,
            items,
            slide,
            navigation,
            OWNER_TYPES,
            OWNER_TYPE_COLLECTION,
            OWNER_TYPE_WISHLIST,
            search,
            select,
            save,
            loading,
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 40px;
}
</style>