import { createStore } from 'vuex';
import editionsModule from './modules/editions.module';
import launchersModule from './modules/launchers.module';
import platformsModule from './modules/platforms.module';
import servicesModule from './modules/services.module';
import regionsModule from './modules/regions.module';
import mediumTypesModule from './modules/medium-types.module';
import genresModule from './modules/genres.module';
import languagesModule from './modules/languages.module';
import propertyModule from './modules/properties.module';
import defectModule from './modules/medium-defect.module';
import themesModule from './modules/themes.module';
import playerPerspectivesModule from './modules/player-perspectives.module';
import gameModesModule from './modules/game-modes.module';
import accountModule from './modules/account.module';
import notificationModule from './modules/notification.module';
import documentModule from './modules/document.module';

export default createStore({

    state: {},
    mutations: {},
    actions: {},

    modules: {
        'editions' : editionsModule,
        'launchers' : launchersModule,
        'platforms' : platformsModule,
        'services': servicesModule,
        'regions': regionsModule,
        'medium-types': mediumTypesModule,
        'genres': genresModule,
        'languages': languagesModule,
        'properties': propertyModule,
        'defects': defectModule,
        'themes': themesModule,
        'player-perspectives': playerPerspectivesModule,
        'game-modes' : gameModesModule,
        'account': accountModule,
        'document': documentModule,
        'notifications': notificationModule,
    },
});
