'use strict';

import GenreEntity from './genre.entity';
import ServiceEntity from './service.entity';
import DateTimeEntity from './date-time.entity';
import MediumEntity from './medium.entity';
import PlatformEntity from './platform.entity';
import PlayerPerspectiveEntity from './player-perspective.entity';
import ThemeEntity from './theme.entity';
import CompanyEntityEntity from './company.entity';
import GameModeEntity from './game-mode.entity';
import { RatingEntity } from './rating.entity';
import ImagesEntity from './images.entity';
import FranchiseEntity from './franchise.entity';
import SeriesEntity from './series.entity';

export default class GameEntity
{
    constructor(id, title, slug)
    {
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.storyline = null;
        this.summary = null;
        this.service = null;
        this.serviceIdentifier = null;
        this.serviceURL = null;
        this.gameMedia = [];
        this.genres = [];
        this.platforms = [];
        this.gameModes = [];
        this.series = [];
        this.franchises = [];
        this.companies = [];
        this.playerPerspectives = [];
        this.themes = [];
        this.releaseDate = null;
        this.rating = null;
        this.images = null;
    }

    static fromObject(data)
    {
        const game = new this(data.id, data.title, data.slug);

        if(null !== data.release_date) {
            game.releaseDate = DateTimeEntity.fromTimestamp(Date.parse(data.release_date));
        }

        game.storyline = data.storyline;
        game.summary = data.summary;
        game.serviceIdentifier = data.service?.game_identifier;
        game.serviceURL = data.service?.url;
        game.service = ServiceEntity.fromObject(data?.service ?? {});
        game.rating = RatingEntity.fromObject(data.rating?.score, data.rating?.count);
        game.images = ImagesEntity.fromObject(data.images ?? {});

        data.series?.map(item => {
            game.series.push(SeriesEntity.fromObject(item));
        });

        data.franchises?.map(item => {
            game.franchises.push(FranchiseEntity.fromObject(item));
        });

        data.mediums?.map(item => {
            game.gameMedia.push(MediumEntity.fromObject(item));
        });

        data.genres?.map(item => {
            game.genres.push(GenreEntity.fromObject(item));
        });

        data.platforms?.map(item => {
            game.platforms.push(PlatformEntity.fromObject(item));
        });

        data.game_modes?.map(item => {
            game.gameModes.push(GameModeEntity.fromObject(item));
        });

        data.themes?.map(item => {
            game.themes.push(ThemeEntity.fromObject(item));
        });

        data.companies?.map(item => {
            game.companies.push(CompanyEntityEntity.fromObject(item));
        });

        data.player_perspectives?.map(item => {
            game.playerPerspectives.push(PlayerPerspectiveEntity.fromObject(item));
        });

        return game;
    }
}