import GenreEntity from '../../entities/game/genre.entity';

export default {

    namespaced: true,

    state : () => ({
        items : []
    }),

    mutations : {

        hydrate(state, genres) {
            state.items = genres;
        }
    },

    actions : {

        hydrate({commit}, items) {

            items.sort((a, b) => a.title.localeCompare(b.title));

            const genres = items.map(item => {
                return GenreEntity.fromObject(item);
            });

            commit('hydrate', genres);
        }
    }
}