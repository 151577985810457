<template>
    <div class="loading-container">
        <div class="loader">
            <cmp-logo v-bind:animated="true" v-bind:delayed="true"></cmp-logo>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        'cmp-logo': require('./logo-cmp.vue').default,
    }
}
</script>

<style>
.loading-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.loading {
    filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.loading.scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
}
.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 125px;
    width: 100%;
}
</style>