'use strict';

import ImageEntity from '@/entities/game/image.entity';

export default class FilesEntity
{
    constructor(image, thumbnail)
    {
        this.image = image ? ImageEntity.fromObject(image) : null;
        this.thumbnail = thumbnail ? ImageEntity.fromObject(thumbnail) : null;
    }

    static fromObject(data)
    {
        return new this(data.image, data.thumbnail);
    }
}