'use strict';

import ResponseEntity from '../../../entities/auth/response.entity';
import AbstractEndpoint from './abstract.endpoint';
import ItemResponse from "../../../entities/response/item.response";
import ErrorResponse from "../../../entities/response/error.response";

const BASE_URL = 'session/';

export default class AccountEndpoint extends AbstractEndpoint
{
    authenticate(username, password) {

        return new Promise((resolve, reject) => {

            this.client
                .post(BASE_URL + 'authenticate', { username, password })
                .then(response => {
                    resolve(new ItemResponse(response.status, response.data.data));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }
}