const initialState = {position: 0};

export default {

    namespaced: true,
    state: initialState,

    actions: {

        scroll({ commit }, position) {

            commit('setPosition', position);
            return Promise.resolve(position);
        },

    },

    mutations: {

        setPosition(state, position) {
            state.position = position;
        }
    }
};
