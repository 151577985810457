<template>
    <section class="media">
        <div class="media-container">
            <div class="previous nav" v-on:click="carousel.previous"><i class="icon-arrow-left"></i></div>
            <ul class="items slide-container" v-on:mousedown="slider.mouseDown" v-on:mouseup="slider.mouseUp" v-on:mousemove="slider.mouseMove" v-on:mouseleave="slider.mouseLeave">
                <li class="item" v-on:click="gallery.open(medium)" v-for="medium in media" v-bind:key="medium.id">
                    <img class="placeholder" src="/images/placeholder-background.png" alt="">
                    <img class="thumbnail" v-bind:src="medium.files.thumbnail.url" alt="">
                    <div class="type">{{ medium.type }}</div>
                    <i class="icon-search-plus zoom"></i>
                </li>
            </ul>
            <div class="next nav" v-on:click="carousel.next"><i class="icon-arrow-right"></i></div>
        </div>
    </section>

    <cmp-gallery
        v-bind:media="media"
        v-bind:current="gallery.current"
        v-bind:enabled="gallery.enabled"
        v-on:current="gallery.current = $event"
        v-on:enabled="gallery.enabled = $event">
    </cmp-gallery>
</template>

<script>

import { reactive } from 'vue';

export default {

    props: {
        media: {
            type: Array,
            required: true
        }
    },

    components: {
        'cmp-gallery': require('../../components/media/gallery-cmp.vue').default,
    },

    setup() {

        const gallery = reactive({

            current: null,
            enabled: false,

            open(medium) {

                gallery.enabled = true;
                gallery.current = medium;
            }
        });

        const slider = {

            isDown: false,
            startX: null,
            scrollLeft: null,
            container: null,
            acceleration: 1,

            mouseDown(e) {

                slider.isDown = true;
                slider.container = e.target.closest('.slide-container');
                slider.startX = e.pageX - slider.container.offsetLeft;
                slider.scrollLeft = slider.container.scrollLeft;
            },

            captureClick(e) {

                e.stopPropagation();
                window.removeEventListener('click', slider.captureClick, true);
            },

            mouseLeave() {
                slider.isDown = false;
            },

            mouseUp() {
                slider.isDown = false;
            },

            mouseMove(e) {

                if(false === slider.isDown){
                    return;
                }

                window.addEventListener('click', slider.captureClick,true);

                e.preventDefault();
                const x = e.pageX - slider.container.offsetLeft;
                const walk = (x - slider.startX) * slider.acceleration;
                slider.container.scrollLeft = slider.scrollLeft - walk;
            },
        }

        const carousel = {

            next() {
                carousel.scrollTo(1);
            },

            previous() {
                carousel.scrollTo(-1);
            },

            scrollTo(position) {

                const elm = document.querySelector('.items');
                const current = elm.scrollLeft;
                const media = [...elm.querySelectorAll('.item')];

                if (position < 0) {
                    media.reverse();
                }

                const scrollPosition = media.find(elm => {
                    return position > 0 ? elm.offsetLeft > current : elm.offsetLeft < current;
                })?.offsetLeft;

                if (undefined === scrollPosition) {
                    return;
                }

                elm.scroll({left: scrollPosition, behavior: 'smooth'});
            }
        }

        return {
            slider,
            gallery,
            carousel,
        }
    }
}
</script>

<style scoped>
.media {
    margin-top: 30px;
    width: 100%;
    position: relative;
    background: var(--theme-hover-color);
    display: flex;
    padding: 15px 0 10px 0;
}
.media .media-container {
    max-width: calc(var(--max-width) - 6%);
    margin: auto;
    position: relative;
}
.media .items {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
}
.media .items::-webkit-scrollbar {
    display: none;
}
.media .items .item:not(:last-child) {
    margin-right: 15px;
}
.media .item {
    max-width: 450px;
    width: 100%;
    display: inline-block;
    position: relative;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
}
.media .item .placeholder {
    width: 100%;
    display: block;
    visibility: hidden;
}
.media .item .thumbnail {
    width: 100%;
    height: 100%;
    transition: transform .4s ease;
    object-fit: cover;
    display: block;
    position: absolute;
    inset: 0;
}
.media .item .zoom {
    display: none;
}
.media .item .type {
    position: absolute;
    inset: 0;
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-shadow: 2px 2px 4px #000;
    text-transform: capitalize;
    justify-content: left;
    align-items: end;
    padding: 7px;
}
.media .nav {
    display: none;
    position: absolute;
    z-index: 3;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    justify-content: center;
    align-items: baseline;
}
.media .nav.next {
    right: 0;
}
.media .nav:hover {
    background: #fff;
}
.media .nav:hover i {
    color: #000;
}
.media .nav i {
    color: #fff;
    font-size: 45px;
}

@media screen and (min-width: 750px) {
    .media .nav {
        display: flex;
    }
    .media .item:hover img {
        transform: scale(1.1);
    }
    .media .item:hover .zoom {
        opacity: .8;
    }
    .media .item .zoom {
        display: initial;
        position: absolute;
        color: #fff;
        font-size: 60px;
        z-index: 2;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        opacity: 0;
        transition: opacity .3s ease-in-out;
        text-shadow: 1px 1px 4px #000;
    }
}
</style>