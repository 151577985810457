'use strict';

import GameEndpoint from './endpoints/game.endpoint';
import GameMediumEndpoint from './endpoints/game-medium.endpoint';
import ServiceEndpoint from './endpoints/service.endpoint';
import ConfigEndpoint from './endpoints/config.endpoint';
import AccountEndpoint from './endpoints/account.endpoint';
import WishlistEndpoint from './endpoints/wishlist.endpoint';
import PlatformEndpoint from './endpoints/platform.endpoint';

export default class APIService
{
    get game() {
        return new GameEndpoint();
    }

    get gameMedium() {
        return new GameMediumEndpoint();
    }

    get wishlist() {
        return new WishlistEndpoint();
    }

    get service() {
        return new ServiceEndpoint();
    }

    get config() {
        return new ConfigEndpoint();
    }

    get platform() {
        return new PlatformEndpoint();
    }

    get account() {
        return new AccountEndpoint();
    }
}