'use strict';

export default class Service
{
    constructor(id, title)
    {
        this.id = parseInt(id);
        this.title = title;
    }

    static fromObject(data)
    {
        return new this(data.id, data.title);
    }
}