import {onBeforeUnmount, onMounted, watch} from "vue";

export default {

    props : ['modal'],

    setup() {

        const close = event => {

            if(!event || event.keyCode === 27 || event.target.dataset.close) {
                this.$emit('close');
            }
        }

        onMounted(() => {
            document.addEventListener('keyup', this.close);
        });

        onBeforeUnmount(() => {
            document.removeEventListener('keyup', this.close);
        });

        watch(() => 'model.enabled', status => {

            switch(status) {

                case true : document.body.classList.add('overflow'); break;
                case false : document.body.classList.remove('overflow'); break;
            }
        });

        return {
            close
        }
    }
}