'use strict';

import ImageTypeEntity from '@/entities/game/image-type.entity';

export default class ImagesEntity
{
    constructor()
    {
        this.cover = null;
        this.screenshots = [];
        this.artwork = [];
    }

    static fromObject(data)
    {
        let instance = new this();

        instance.cover = ImageTypeEntity.fromObject(data.cover ?? {});

        data.screenshots && data.screenshots.map(item => {
            instance.screenshots.push(ImageTypeEntity.fromObject(item ?? {}));
        });

        data.artwork && data.artwork.map(item => {
            instance.artwork.push(ImageTypeEntity.fromObject(item ?? {}));
        });

        return instance;
    }
}