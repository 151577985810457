'use strict';

import EditionEntity from './edition.entity';
import PlatformEntity from './platform.entity';
import LanguageEntity from './language.entity';
import TypeEntity from './type.entity';
import RegionEntity from './region.entity';
import ConditionEntity from './condition.entity';
import PropertyEntity from "./property.entity";
import LauncherEntity from './launcher.entity';
import MediumDefectEntity from './medium-defect.entity';
import GameEntity from './game.entity';

export default class MediumEntity
{
    constructor(id)
    {
        this.id = id;
        this.edition = null;
        this.launcher = null;
        this.type = null;
        this.game = null;
        this.region = null;
        this.platform = {};
        this.languages = [];
        this.properties = [];
        this.defects = [];
        this.sealed = false;
        this.notes = null;
        this.condition = new ConditionEntity(0);
    }

    static fromObject(data)
    {
        const medium = new this(data.id);

        if(undefined !== data.edition && null !== data.edition) {
            medium.edition = EditionEntity.fromObject(data.edition);
        }

        if(undefined !== data.launcher && null !== data.launcher) {
            medium.launcher = LauncherEntity.fromObject(data.launcher);
        }

        if(undefined !== data.type && null !== data.type) {
            medium.type = TypeEntity.fromObject(data.type);
        }

        if(undefined !== data.game && null !== data.game) {
            medium.game = GameEntity.fromObject(data.game);
        }

        if(undefined !== data.region && null !== data.region) {
            medium.region = RegionEntity.fromObject(data.region);
        }

        if(undefined !== data.is_factory_sealed && null !== data.is_factory_sealed) {
            medium.sealed = data.is_factory_sealed;
        }

        if(undefined !== data.platform && null !== data.platform) {
            medium.platform = PlatformEntity.fromObject(data.platform);
        }

        if(undefined !== data.condition && null !== data.condition) {
            medium.condition = new ConditionEntity(data.condition);
        }

        if(undefined !== data.notes && null !== data.notes) {
            medium.notes = data.notes;
        }

        if(undefined !== data.languages && null !== data.languages) {

            data.languages.map(item => {
                medium.languages.push(LanguageEntity.fromObject(item))
            });
        }

        if(undefined !== data.properties && null !== data.properties) {

            data.properties.map(item => {
                medium.properties.push(PropertyEntity.fromObject(item))
            });
        }

        if(undefined !== data.defects && null !== data.defects) {

            data.defects.map(item => {
                medium.defects.push(MediumDefectEntity.fromObject(item))
            });
        }

        return medium;
    }
}