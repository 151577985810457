'use strict';

import AbstractEndpoint from './abstract.endpoint';
import ItemResponse from "../../../entities/response/item.response";
import ErrorResponse from "../../../entities/response/error.response";
import CollectionResponse from "../../../entities/response/collection.response";
import PlatformEntity from "../../../entities/game/platform.entity";

const BASE_URL = 'config';

export default class ConfigEndpoint extends AbstractEndpoint
{
    fetch(force = false) {

        return new Promise((resolve, reject) => {

            let url = BASE_URL;

            if(true === force) {
                url += '?' + (new Date().getTime());
            }

            this.client
                .get(url)
                .then(response => {
                    resolve(new ItemResponse(response.status, response.data.data));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }

    updatePlatforms(platforms) {

        return new Promise((resolve, reject) => {

            this.client
                .put(BASE_URL + '/platforms', {platforms})
                .then(response => {
                    resolve(new ItemResponse(response.status, response.data.data));
                })
                .catch(error => {
                    reject(new ErrorResponse(error.response.status));
                })
        });
    }
}