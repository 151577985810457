export default class NotificationEntity
{
    constructor(title, type, message, enabled = true)
    {
        this.title = title;
        this.type = type;
        this.message = message;
        this.enabled = enabled;
        this.timestamp = new Date().getTime();
    }
}
