import EditionEntity from '../../entities/game/edition.entity';

export default {

    namespaced: true,

    state : () => ({
        items : []
    }),

    mutations : {

        hydrate(state, editions) {
            state.items = editions;
        }
    },

    actions : {

        hydrate({commit}, items) {

            items.sort((a, b) => a.title.localeCompare(b.title));

            const editions = items.map(item => {
                return EditionEntity.fromObject(item);
            });

            commit('hydrate', editions);
        }
    }
}