<template>
    <footer>Kris Kuiper - Game Collection {{ year }}</footer>
</template>

<script>
export default {

    setup() {
        return {
            year : new Date().getFullYear()
        }
    }
}
</script>