export function usePage(current = 1, limit = 10, total = 0) {
    return new Page(current, total, limit);
}

export class Page {

    constructor(current = 1, total = 0, limit = 10, from = 1, to = 0, amount = 0)
    {
        this.current = parseInt(current);
        this.total = parseInt(total);
        this.limit = parseInt(limit);
        this.to = parseInt(to);
        this.from = parseInt(from);
        this.amount = parseInt(amount);
    }

    get offset() {

        const offset = ((this.current - 1) * this.limit) || 0;
        return offset < 0 ? 0 : offset;
    }

    fromObject(data) {

        this.total = data.total;
        this.limit = data.limit;
        this.amount = data.amount;
        this.from = this.offset + 1;
        this.to = Math.min(parseInt(this.offset) + parseInt(this.limit), parseInt(this.total));
    }
}

class PageItem {

    constructor(page = 1, text = 1, active = false, className = null)
    {
        this.page = page;
        this.text = text;
        this.active = active;
        this.className = className;
    }
}

export class Pagination {

    constructor(current = 1, amount = 0, show = 5) {

        this.show = parseInt(show);
        this.amount = parseInt(amount);
        this.current = parseInt(current);
    }

    get pages() {

        const pages = [];
        const avg = Math.floor(this.show / 2);

        //Calculate marge
        let marge = { left: avg, right: avg };

        if(this.current <= marge.left) {
            marge.left  = this.current - 1;
            marge.right = this.show > this.amount ? this.amount - marge.left : this.show - marge.left - 1;
        }
        else if(this.current >= this.amount + 1 - marge.left) {

            marge.right = this.amount - this.current + 1;
            marge.left  = this.show > this.amount ? this.amount - marge.right : this.show - marge.right - 1;
        }

        marge.left = this.current - marge.left;
        marge.right = marge.right >= this.amount ? this.amount < 1 ? 1 : this.amount : this.current + marge.right - 1;

        //Add previous button if there are more than 1 pages
        if(marge.left !== marge.right && this.current > 1) {

            const previous = this.current - 1 < 1 ? 1 : this.current - 1;
            pages.push(new PageItem(previous, '‹', false,'previous'));
        }

        for(let i = marge.left; i <= marge.right; i++) {
            pages.push(new PageItem(i, i, this.current === i));
        }

        //Add next button if there are more than 1 pages
        if(marge.left !== marge.right && this.current < this.amount) {

            const next = this.current + 1 > marge.right ? marge.right : this.current + 1;
            pages.push(new PageItem(next, '›', false, 'next'));
        }

        return pages;
    }
}