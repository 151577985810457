'use strict';

export default class DateTimeEntity
{
    constructor(date)
    {
        this.date = date;
    }

    static fromTimestamp(timestamp)
    {
        const date = new Date(timestamp);
        return new this(date);
    }

    toString() {

        if (null === this.date) {
            return null;
        }

        const date = this.date;

        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
        let mo = new Intl.DateTimeFormat('en', {month: 'long'}).format(date);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
        return `${da} ${mo} ${ye}`;
    }
}