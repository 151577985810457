<template>
    <div class="content" v-if="enabled">
        <div class="content-container">
            <h2>Recommended games</h2>
            <div class="recommendations">
                <router-link v-bind:to="{ name: 'game.detail', params: { id: item.id, slug: item.slug }}" v-for="item in items" v-bind:key="item.id" class="item">
                    <div class="image">
                        <img class="placeholder-cover" src="/images/placeholder-cover.png" alt="">
                        <img v-if="item.images?.cover?.files?.thumbnail" v-bind:src="item.images.cover.files.thumbnail.url" alt="" class="cover">
                    </div>
                    <span class="title">{{ item.title }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import APIService from '../../services/api/api.service';

export default {

    props: {
        id: {
            type: String
        }
    },

    setup(props) {

        const api = new APIService();
        const items = ref(null);
        const enabled = ref(false);

        const fetch = id => {

            api.game.recommendations(id).then(response => {

                items.value = response.items;
                enabled.value = items.value.length > 0;
            });
        }

        watch(() => props.id, () => {
            fetch(props.id);
        });

        onMounted(() => {
            fetch(props.id);
        });

        return {
            items,
            enabled
        }
    }
}
</script>

<style scoped>
.content-container {
    max-width: calc(var(--max-width) - 6%);
    margin: auto;
}
h2 {
    font-size: 1.5rem;
    text-align: center;
}
.content {
    margin-top: 50px
}
.recommendations {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 10px;
}
.recommendations .cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
    max-height: 264px;
    position: absolute;
    inset: 0;
    transform: none;
}
.recommendations .item {
    padding: 20px;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: none;
}
.recommendations .item:nth-child(1) {
    display: block;
}
.recommendations .item:hover {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .2);
}
.recommendations .image {
    position: relative;
    display: block;
}
.recommendations .image .placeholder-cover {
    width: 100%;
    max-height: 264px;
    display: block;
    border-radius: 3px;
}
.placeholder-cover {
    animation: progress-bar-stripes 2s linear infinite;
    background-image: linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
    background-color: #ddd;
}
.recommendations .item .title {
    display: inline-block;
    margin-top: 10px;
}

@media screen and (min-width: 250px) {
    .recommendations {
        grid-template-columns: repeat(2, 1fr);
    }
    .recommendations .item:nth-child(2) {
        display: block;
    }
}
@media screen and (min-width: 450px) {
    .recommendations {
        grid-template-columns: repeat(3, 1fr);
    }
    .recommendations .item:nth-child(3) {
        display: block;
    }
}
@media screen and (min-width: 650px) {
    .recommendations {
        grid-template-columns: repeat(4, 1fr);
    }
    .recommendations .item:nth-child(4) {
        display: block;
    }
}
@media screen and (min-width: 850px) {
    .recommendations {
        grid-template-columns: repeat(5, 1fr);
    }
    .recommendations .item:nth-child(5) {
        display: block;
    }
}
@media screen and (min-width: 1050px) {
    .recommendations {
        grid-template-columns: repeat(6, 1fr);
    }
    .recommendations .item:nth-child(6) {
        display: block;
    }
}
@media screen and (min-width: 1250px) {
    .recommendations {
        grid-template-columns: repeat(7, 1fr);
    }
    .recommendations .item:nth-child(7) {
        display: block;
    }
}
</style>
