import APIService from '../api/api.service';
import { useStore } from 'vuex';

export default class ConfigService {

    constructor()
    {
        this.store = useStore();
        this.apiService = new APIService();
    }

    load(force = false) {

        return this.apiService.config.fetch(force)
            .then(async response => {

                const config = response.item;

                await this.store.dispatch('editions/hydrate', config.editions);
                await this.store.dispatch('launchers/hydrate', config.launchers);
                await this.store.dispatch('game-modes/hydrate', config.gameModes);
                await this.store.dispatch('platforms/hydrate', config.platforms);
                await this.store.dispatch('services/hydrate', config.services);
                await this.store.dispatch('regions/hydrate', config.regions);
                await this.store.dispatch('medium-types/hydrate', config.mediumTypes);
                await this.store.dispatch('player-perspectives/hydrate', config.playerPerspectives);
                await this.store.dispatch('genres/hydrate', config.genres);
                await this.store.dispatch('languages/hydrate', config.languages);
                await this.store.dispatch('properties/hydrate', config.properties);
                await this.store.dispatch('defects/hydrate', config.defects);
                await this.store.dispatch('themes/hydrate', config.themes);
            })
            .catch(error => {

                if(401 === error.status) {
                    return this.store.dispatch('account/logout');
                }
            });
    }
}