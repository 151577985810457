import RegionEntity from '../../entities/game/region.entity';

export default {

    namespaced: true,

    state : () => ({
        items : []
    }),

    mutations : {

        hydrate(state, regions) {
            state.items = regions;
        }
    },

    actions : {

        hydrate({commit}, items) {

            items.sort((a, b) => a.title.localeCompare(b.title));

            const regions = items.map(item => {
                return RegionEntity.fromObject(item);
            });

            commit('hydrate', regions);
        }
    }
}