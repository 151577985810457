<template>
    <div class="overview">
        <div class="item-container">
            <div class="items" v-if="items.length > 0">
                <template v-for="item in items" v-bind:key="item.id">
                    <label class="item">
                        <input type="radio" name="item" v-on:click="select(item)">
                        <span class="image">
                            <img src="/images/placeholder-cover.png" class="placeholder-cover" alt="">
                            <img v-if="item.cover" v-bind:src="item.cover" alt="" class="cover">
                            <img v-else src="/images/no-cover.png" alt="" class="cover">
                            <span class="selected">
                                <i class="icon-success"></i>
                            </span>
                        </span>
                        <span class="title">{{ item.title }}</span>
                    </label>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {

    emits: ['select'],

    props: {
        items: {
            type: Array,
            default: () => []
        }
    },

    setup(props, {emit}) {

        const item = ref(null);

        const select = item => {
            item.value = item;
            emit('select', item);
        }

        return {
            select,
            item,
        }
    }
}
</script>

<style scoped>
.item-container {
    width: 100%;
}

@media screen and (min-width: 1024px) {
    .items {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (min-width: 750px) and (max-width: 1023px) {
    .items {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>