<template>
    <div class="panel-container panel-right" v-bind:class="{'active': enabled}">
        <div class="options content">
            <section class="panel-title">
                <h3>Options:</h3>
                <i class="close icon-close" v-on:click="option.close"></i>
            </section>
            <div class="form-group">
                <select id="input-sort" required="required" class="input-label" title="" v-model="options.sort.value" v-on:change="option.update">
                    <option v-for="(title, value) in sortOptions" v-bind:key="value" v-bind:value="value">{{ title }}</option>
                </select>
                <label for="input-sort">Sort on</label>
            </div>
            <div class="form-group">
                <select id="input-order" required="required" class="input-label" title="" v-model="options.order.value" v-on:change="option.update">
                    <option v-bind:value="ORDER_TYPE_ASC">Ascending</option>
                    <option v-bind:value="ORDER_TYPE_DESC" selected>Descending</option>
                </select>
                <label for="input-order">Order</label>
            </div>
            <div class="form-group">
                <select id="input-items" required="required" class="input-label" title="" v-model="options.limit.value" v-on:change="option.update">
                    <option value="8">8</option>
                    <option value="16">16</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                    <option value="100">100</option>
                </select>
                <label for="input-items">Items per page</label>
            </div>
        </div>
        <div class="background" v-on:click="option.close"></div>
    </div>
</template>

<script>
import { ref, watch} from 'vue';
import { DEFAULT_OVERVIEW_ORDER, DEFAULT_OVERVIEW_LIMIT, SORT_TYPE_ADDED, SORT_TYPE_TITLE, SORT_TYPE_RATING, SORT_TYPE_RELEASE, ORDER_TYPE_DESC, ORDER_TYPE_ASC, SORT_TYPE_RELEVANCE } from '@/constants';

export default {

    props: {
        enabled: {
            type: Boolean,
            default: false,
        },
        sortOptions: {
            type: Object,
            default: () => {
                return {
                    [SORT_TYPE_TITLE]: 'Title',
                    [SORT_TYPE_RELEVANCE]: 'Relevance',
                    [SORT_TYPE_ADDED]: 'Added',
                    [SORT_TYPE_RELEASE]: 'Release date',
                    [SORT_TYPE_RATING]: 'Rating',
                }
            }
        },
        selected : {
            type: Object,
            default: () => {},
        },
    },

    setup(props, {emit}) {

        const options = {

            sort: ref(props.selected.sort),
            order: ref(DEFAULT_OVERVIEW_ORDER),
            limit: ref(DEFAULT_OVERVIEW_LIMIT),
        }

        const option = {

            close() {
                emit('close');
            },

            update() {

                const query = {
                    sort: options.sort.value,
                    order: options.order.value,
                    limit: options.limit.value,
                };

                emit('update', query);
            },

            fill() {

                for(const type in props.selected) {

                    if(undefined === options[type]) {
                        continue;
                    }

                    options[type].value = props.selected[type];
                }
            }
        };

        watch(props.selected, () => {
            option.fill();
        });

        return {
            option,
            options,
            SORT_TYPE_ADDED,
            SORT_TYPE_TITLE,
            SORT_TYPE_RATING,
            SORT_TYPE_RELEASE,
            ORDER_TYPE_DESC,
            ORDER_TYPE_ASC,
        }
    }
}
</script>

<style>
@media screen and (min-width: 750px) {
    .panel-container.panel-right  {
        width: 100%;
    }
    .panel-container .options .panel-title {
        display: none;
    }
    .options.content {
        display: grid;
        width: calc(70% - 23px);
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        float: right;
    }
}
@media screen and (max-width: 749px) {
    .panel-container .options {
        padding: 10px 20px;
    }
    .panel-container .form-group {
        margin-bottom: 20px;
    }
}
</style>