'use strict';

import GameSuggestEntity from '../game/game-suggest.entity';

export default class PlatformSuggest
{
    constructor(items)
    {
        this.items = items;
    }

    static fromResponse(data)
    {
        // Create an object to hold the platforms and their corresponding games
        const platforms = [];

        data.forEach(item => {

            const games = [];

            item.games.forEach(game => {

                // Add the game to the platform array
                games.push(GameSuggestEntity.fromObject(game));
            });

            platforms.push({
                id: item.platform.id,
                title: item.platform.title,
                slug: item.platform.slug,
                games
            })
        })

        return new this(platforms);
    }
}