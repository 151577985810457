<template>
    <transition name="modal-bg-transition">
        <div v-if="enabled" class="modal-bg" data-close="true" v-on:click="close"></div>
    </transition>
    <transition name="modal-transition">
        <div v-if="enabled" class="modal warning">
            <div class="close">
                <span v-on:click="close" class="icon-close" data-close="true" ></span>
            </div>
            <div class="content">
                <div>
                    <span v-bind:class="'icon-' + type"></span>
                </div>
                <div v-if="type === 'warning'">
                    <h3>Warning!</h3>
                </div>
                <div>
                    <slot name="content"></slot>
                </div>
            </div>
            <div class="footer">
                <button v-on:click="close" class="btn" data-close="true" >Cancel</button>
                <slot name="actions"></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    emits: ['close'],

    props: {
        enabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'warning',
        },
    },

    mixins : [require('../../mixins/modal.js').default],

    setup(props, {emit}) {

        const close = () => {
            emit('close');
        }

        return {
            close
        }
    }
}
</script>