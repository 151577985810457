export default class JwtService
{
    static parse()
    {
        const user = JSON.parse(localStorage.getItem('user'));

        if(null === user) {
            return null;
        }

        const base64Url = user.access_token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
