'use strict';

import * as RATING from '../../constants';

const ratings = [

    {rating: 15, text: RATING.GRADE_AWFUL},
    {rating: 25, text: RATING.GRADE_VERY_BAD},
    {rating: 35, text: RATING.GRADE_BAD},
    {rating: 45, text: RATING.GRADE_UNIMPRESSIVE},
    {rating: 55, text: RATING.GRADE_AVERAGE},
    {rating: 65, text: RATING.GRADE_FAIR},
    {rating: 75, text: RATING.GRADE_ALRIGHT},
    {rating: 85, text: RATING.GRADE_GOOD},
    {rating: 95, text: RATING.GRADE_GREAT},
    {rating: 101, text: RATING.GRADE_SUPERB},
];

export class RatingEntity
{
    constructor(rating, counter)
    {
        this.rating = Math.round(rating);
        this.counter = parseInt(counter);
    }

    get description()
    {
        const description = ratings.find(rating => this.rating < rating.rating);
        return description.text;
    }

    static fromObject(rating, ratingCounter)
    {
        return new this(rating || 0, ratingCounter || 0);
    }
}