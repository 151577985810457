<template>
    <div v-if="page.total > 0" class="pagination">
        <div class="details">
            <span v-if="page.total < 10000">Item {{ page.from }} to {{ page.to }} of {{ page.total}} items.</span>
            <span v-else>Item {{ page.from }} to {{ page.to }} items.</span>
        </div>
        <ul class="pages">
            <li v-for="(page, index) in pages" v-bind:key="index">
                <router-link v-bind:to="{name : page.link, query : {...route.query, page: page.page}}" v-bind:class="{'active' : page.active, [page.className]: true}">{{ page.text }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { Pagination } from '@/library/pagination';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {

    props: {
        page: {}
    },

    setup(props, {emit}) {

        const pages = ref([]);
        const route = useRoute();

        const pagination = () => {

            const amount = Math.ceil(props.page.total / props.page.limit);
            const current = props.page.current;
            let pagination = new Pagination(current, amount);
            pages.value = pagination.pages;
        };

        const fetch = () => {
            emit('fetch', pagination);
        };

        watch(props.page, pagination);

        return {
            pages,
            route,
            fetch
        }
    }
}
</script>

<style>
.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.pagination select {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 0 2px 0 5px;
    width: auto;
    min-width: 60px;
}
.pagination .pages {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.pagination .pages li {
}
.pagination .pages a {
    height: 34px;
    width: 34px;
    padding: 6px;
    display: block;
    border-radius: 4px;
    color: #2e2e2e;
    background: #fff;
    border: 1px solid #ddd;
    text-align: center;
    transition: background-color .5s, border .5s;
}
.pagination .pages a:hover {
    text-decoration: none;
    background: #333;
    color: #fff;
    border-color: #333;
}
.pagination .pages a.active {
    background: var(--theme-color);
    border-color: var(--theme-color);
    color: #fff;
}
.pagination .pages a.previous, .pagination .pages a.next {
    font-size: 24px;
    line-height: 18px;
}

@media screen and (max-width: 900px) {

    .pagination {
        flex-wrap: wrap;
    }

    .pagination .details {
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .pagination .pages {
        flex-basis: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 480px) {

    .pagination .details {
        margin-bottom: 0;
    }

    .pagination .details span {
        display: block;
        margin-bottom: 12px;
    }
}
</style>