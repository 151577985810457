<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">

            <div class="breadcrumb">
                <router-link v-bind:to="{name: 'game.overview'}">Home</router-link> / Wishlist overview
            </div>

            <div class="header">
                <div class="buttons">
                    <input type="button" class="btn" value="Options" v-on:click="options.open">
                </div>
                <cmp-options v-bind:selected="selected.options" v-bind:sort-options="sort" v-on:update="options.update" v-on:close="options.close" v-bind:enabled="options.enabled.value"></cmp-options>
            </div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Title</th>
                        <th>Release date</th>
                        <th>Priority</th>
                        <th>Platform</th>
                        <th>Added</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td v-bind:data-table="item.game.title">
                            <div class="image">
                                <router-link v-bind:to="{name: 'game.detail', params: {id: item.game.id, slug: item.game.slug}}">
                                    <img class="placeholder-cover" src="/images/placeholder-cover.png" alt="">
                                    <img v-if="item.game?.images?.cover?.files?.thumbnail" v-bind:src="item.game.images.cover.files.thumbnail.url" alt="" class="cover">
                                    <img v-else src="/images/no-cover.png" alt="" class="cover">
                                </router-link>
                            </div>
                        </td>
                        <td data-table="Title" class="title"><router-link v-bind:to="{name: 'game.detail', params: {id: item.game.id, slug: item.game.slug}}">{{ item.game.title }}</router-link></td>
                        <td data-table="Release date">{{ item.game.releaseDate ? item.game.releaseDate : 'Unknown' }}</td>
                        <td data-table="Priority">{{ item.priority.description }}</td>
                        <td data-table="Platform">{{ item.platform.title }}</td>
                        <td data-table="Added">{{ item.added.toString() }}</td>
                        <td>
                            <div class="icons-container" style="">
                                <router-link v-bind:to="{name: 'wishlist.edit', params: {id: item.id}}"><span class="icon-bg icon-edit"></span></router-link>
                                <router-link v-bind:to="{name: 'wishlist.add', params: {id: item.id}}"><span class="icon-bg icon-cart"></span></router-link>
                                <span v-on:click="modal.open(item)" class="icon-bg icon-bin"></span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="0 === items.length">
                        <td colspan="20">No games has been added to the wishlist yet.</td>
                    </tr>
                </tfoot>
            </table>

            <cmp-pagination v-bind:page="page"></cmp-pagination>
        </div>
    </div>

    <cmp-modal v-bind:enabled="modal.enabled" v-on:close="modal.close">
        <template v-slot:content>Are you sure you want to delete the selected game from the wishlist?</template>
        <template v-slot:actions>
            <button v-on:click="modal.remove" class="btn">Remove game from wishlist</button>
        </template>
    </cmp-modal>

    <cmp-footer></cmp-footer>
</template>

<script>
import { onMounted, reactive, ref} from 'vue';
import {DEFAULT_OVERVIEW_LIMIT, DEFAULT_OVERVIEW_ORDER, SORT_TYPE_PLATFORM, SORT_TYPE_PRIORITY, SORT_TYPE_RELEASE_DATE, SORT_TYPE_TITLE, SORT_TYPE_ADDED} from '../../constants';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { usePage } from '../../library/pagination';
import { useStore } from 'vuex';
import NotificationEntity from '../../entities/notification.entity';
import APIService from '../../services/api/api.service';

export default {

    emits: ['loading'],

    components: {

        'cmp-options': require('../../components/overview/options-cmp.vue').default,
        'cmp-pagination': require('../../components/pagination/pagination-cmp.vue').default,
        'cmp-modal': require('../../components/modal/remove-cmp.vue').default,
        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
    },

    setup(props, {emit}) {

        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const page = reactive(usePage(parseInt(route.query.page) > 0 ? route.query.page : 1, DEFAULT_OVERVIEW_LIMIT));
        const api = new APIService();
        const items = ref([]);
        const excluded = [];

        const status = {
            loaded: ref(false)
        }

        const selected = reactive({

            options: {
                sort: SORT_TYPE_PRIORITY,
                order: DEFAULT_OVERVIEW_ORDER,
                limit: page.limit,
            },

            fill(route) {

                selected.options.sort = SORT_TYPE_PRIORITY;
                selected.options.order = DEFAULT_OVERVIEW_ORDER;
                selected.options.limit = route.query.limit || page.limit;

                for(const type in route.query) {

                    if(undefined !== selected.options[type]) {
                        selected.options[type] = route.query[type];
                    }
                }
            },

            getQuery() {

                const query = {
                    page : page.current,
                };

                //Options
                for(const type in selected.options) {
                    query[type] = selected.options[type];
                }

                query.exclude = excluded;

                //Page
                query.limit = parseInt(selected.options.limit);

                return query;
            }
        });

        const options = {

            enabled: ref(false),

            open() {
                options.enabled.value = true;
            },

            close() {
                options.enabled.value = false;
            },

            update(items) {

                for(const type in items) {

                    if(undefined !== selected.options[type]) {
                        selected.options[type] = items[type];
                    }
                }

                page.current = 1;

                router.push({
                    name: route.name,
                    query: selected.getQuery()
                });
            }
        };

        const fetch = () => {

            let loadingId = 'wishlist-fetch';

            emit('loading', loadingId);

            api.wishlist.list({...selected.getQuery()})
                .then(response => {

                    page.fromObject(response.pagination);
                    items.value = response.items;
                    status.loaded.value = true;
                })
                .catch(error => {

                    if(401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                })
                .finally(() => {
                    emit('loading', loadingId);
                });
        }

        const sort = {
            [SORT_TYPE_ADDED]: 'Added',
            [SORT_TYPE_PLATFORM]: 'Platform',
            [SORT_TYPE_PRIORITY]: 'Priority',
            [SORT_TYPE_RELEASE_DATE]: 'Release date',
            [SORT_TYPE_TITLE]: 'Title',
        }

        const modal = reactive({

            selected: null,
            enabled: false,

            open(item) {
                modal.selected = item;
                modal.enabled = true;
            },

            close: () => {
                modal.enabled = false;
            },

            async remove() {

                let loadingId = 'wishlist-remove';

                emit('loading', loadingId);

                const id = modal.selected.id;

                api.wishlist
                    .remove(id)
                    .then(() => {

                        store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully deleted game from wishlist.'));
                        excluded.push(id);

                        fetch();
                    })
                    .catch(error => {

                        if(401 === error.status) {
                            return store.dispatch('account/logout');
                        }
                    })
                    .finally(() => {

                        emit('loading', loadingId);
                        modal.close();
                    });
            }
        });

        onBeforeRouteUpdate((to, from) => {

            if(to.name === from.name) {

                page.current = (to.query.page || 0) > 0 ? to.query.page : 1;
                selected.fill(to);
                fetch();
            }
        })

        onMounted(() => {

            selected.fill(route);
            fetch();
        });

        return {
            items,
            selected,
            options,
            page,
            modal,
            sort
        }
    }
}
</script>

<style scoped>
.header {
    margin: 0 0 30px 0;
}
.pagination {
    padding: 30px 0 0 0;
}
.icons-container {
    display: flex;
    gap: 5px;
    margin-right: 5px;
    height: 50px;
}
.image .cover {
    max-height: 80px;
    max-width: 65px;
    min-width: 40px;
    left: 0;
    transform: translate(0, -50%);
}
.image .placeholder-cover {
    max-height: 80px;
    max-width: 65px;
    min-width: 40px;
}
@media screen and (min-width: 750px) {
    tr td:last-child {
        width: 1%;
        white-space: nowrap;
    }
    .add.active {
        display: table-row;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image .cover {
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>