'use strict';

export default class CompanyEntity
{
    constructor(title, role)
    {
        this.title = title;
        this.role = role;
    }

    static fromObject(data)
    {
        return new this(data.title, data.role);
    }
}