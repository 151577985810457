const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export default {

    namespaced: true,
    state: initialState,

    actions: {

        login({ commit }, user) {

            localStorage.setItem('user', JSON.stringify(user));
            commit('loginSuccess', user);
            return Promise.resolve(user);
        },

        logout({ commit }) {

            localStorage.removeItem('user');
            commit('logout');
        }
    },

    mutations: {

        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },

        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        }
    }
};
