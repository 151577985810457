<template>
    <vee-form v-bind:ref="el => forms.wishlist.value = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.wishlist">
        <ul class="form">

            <!-- Game -->
            <li v-if="title" class="form-group">
                <input id="input-game" name="game" type="text" v-bind:value="title" disabled class="input-label">
                <label for="input-game">Game</label>
            </li>

            <!-- Platform -->
            <li class="form-group">
                <vee-field id="input-platform" name="platform" as="select" v-model="model.platform" class="input-label" autofocus required="required" title="" v-bind:class="{error: errors.platform}">
                    <option v-for="item in wishlist.platforms.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-platform">Platform</label>
                <vee-error-message name="platform" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Medium type -->
            <li class="form-group">
                <vee-field id="input-medium-type" name="medium-type" as="select" v-model="model.mediumType" class="input-label" required="required" title="" v-bind:class="{error: errors['medium-type']}">
                    <option v-for="item in wishlist.mediumTypes.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-medium-type">Medium type</label>
                <vee-error-message name="medium-type" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Editions -->
            <li class="form-group">
                <vee-field id="input-edition" name="edition" as="select" v-model="model.edition" class="input-label" required="required" title="" v-bind:class="{error: errors.edition}">
                    <option v-for="item in wishlist.editions.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-edition">Editions</label>
                <vee-error-message name="edition" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Priority -->
            <li class="form-group">
                <vee-field id="input-priority" name="priority" as="select" v-model="model.priority" class="input-label" required="required" title="" v-bind:class="{error: errors.priority}">
                    <option value="1">Lowest</option>
                    <option value="2">Low</option>
                    <option value="3">Neutral</option>
                    <option value="4">High</option>
                    <option value="5">Highest</option>
                </vee-field>
                <label for="input-priority">Priority</label>
                <vee-error-message name="priority" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <li class="align-right">
                <slot></slot>
                <input value="Save" v-on:click="save" type="button" class="btn">
            </li>
        </ul>
    </vee-form>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {configure} from 'vee-validate';
import NotificationEntity from '../../entities/notification.entity';
import * as yup from 'yup';

export default {

    emits: ['save'],

    props: {
        platform: {
            type: Number,
            default: null
        },
        edition: {
            type: Number,
            default: 8
        },
        mediumType: {
            type: Number,
            default: 1
        },
        priority: {
            type: Number,
            default: 3
        },
        title: {
            type: String,
            default: null,
        },
    },

    components: {

        'vee-form': require('vee-validate').Form,
        'vee-field': require('vee-validate').Field,
        'vee-error-message': require('vee-validate').ErrorMessage,
    },

    setup(props, { emit }) {

        configure({
            validateOnBlur: false
        });

        const store = useStore();

        const model = reactive({
            platform: props.platform,
            edition: props.edition,
            priority: props.priority,
            mediumType: props.mediumType,
        });

        const forms = {

            isValid() {

                return new Promise((resolve, reject) => {

                    forms.wishlist.value.validate().then(response => {

                        if(true === response.valid) {
                            return resolve(true);
                        }

                        reject(false);
                    });
                });
            },

            schemes: {

                wishlist: yup.object().shape({
                    platform: yup.string().nullable().required('Platform is required'),
                    edition: yup.string().nullable().required('Edition is required'),
                    priority: yup.string().nullable().required('Priority is required'),
                    'medium-type': yup.string().nullable().required('Medium type is required'),
                })
            },

            wishlist: ref(),
        }

        const wishlist = {

            platforms: computed(() => store.state.platforms.items),
            editions: computed(() => store.state.editions.items),
            mediumTypes: computed(() => store.state['medium-types'].items),
        }

        const save = () => {

            forms.isValid().then(() => {
                emit('save', model);
            }).catch(() => {
                store.dispatch('notifications/add', new NotificationEntity('Warning', 'warning', 'There where some validation errors.'));
            })
        }

        watch(() => props.platform, () => {
            model.platform = props.platform;
        });

        return {
            wishlist,
            model,
            save,
            forms,
        }
    }
}
</script>