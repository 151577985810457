'use strict';

import FilesEntity from "@/entities/game/files.entity";

export default class ImageTypeEntity
{
    constructor(id)
    {
        this.id = id;
        this.files = null;
    }

    static fromObject(data)
    {
        let instance = new this(data.id);
        instance.files = FilesEntity.fromObject(data.files ?? {});

        return instance;
    }
}