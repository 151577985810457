<template>
    <svg xml:space="preserve" viewBox="4 28 93 45" xmlns="http://www.w3.org/2000/svg" class="controller" v-bind:class="{'animate': animated, 'hover': hover, 'delay': delayed}">
        <g class="background">
            <path d="M74.3 30.4c-3.8-.3-7.3.5-10.4 2.1-.8.4-1.6.6-2.5.6H38.6c-.9 0-1.7-.2-2.5-.6-3.1-1.6-6.6-2.4-10.4-2.1-10.2.7-18.3 9.5-18.2 19.7.1 10.8 8.9 19.5 19.7 19.5 5.5 0 10.5-2.3 14.1-5.9 1.1-1.1 2.6-1.7 4.1-1.7h9.4c1.5 0 3 .6 4.1 1.7 3.6 3.7 8.5 5.9 14.1 5.9 10.8 0 19.6-8.7 19.7-19.5-.1-10.2-8.2-19-18.4-19.7z"></path>
        </g>
            <g class="arrows">
            <path d="M35.7 47.4h-6v-6c0-.6-.5-1.1-1.1-1.1h-2.9c-.6 0-1.1.5-1.1 1.1v6h-6c-.6 0-1.1.5-1.1 1.1v2.9c0 .6.5 1.1 1.1 1.1h6v6c0 .6.5 1.1 1.1 1.1h2.9c.6 0 1.1-.5 1.1-1.1v-6h6c.6 0 1.1-.5 1.1-1.1v-2.9c.1-.6-.4-1.1-1.1-1.1z"></path>
        </g>
            <g class="button btn-a">
            <circle r="3.4" cy="58" cx="72.8"></circle>
        </g>
            <g class="button btn-b">
            <circle r="3.4" cy="50" cx="80.8"></circle>
        </g>
            <g class="button btn-c">
            <circle r="3.4" cy="42" cx="72.8"></circle>
        </g>
            <g class="button btn-d">
            <circle r="3.4" cy="50" cx="64.8"></circle>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        animated: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        delayed: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
@keyframes breath {

    0% {
        transform: scale(0.9);
    }
    51% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(0.9);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.controller.delay {
    opacity: 0;
    animation: fadeIn 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}
.controller .background path {
    fill: rgb(60, 60, 60);
    stroke: var(--theme-color);
    stroke-width: 3;
}
.controller.animate .background, .controller.hover:hover .background {
    transform: scale(1);
    transform-origin: 45px 45px 0;
    animation: 1.2s linear -0.65s infinite normal forwards running breath;
}
.controller .arrows path {
    fill: rgb(224, 224, 224);
}
.controller.animate .arrows, .controller.hover:hover .arrows {
    transform: scale(1);
    transform-origin: 30px 45px 0;
    animation: 1.2s linear -0.75s infinite normal forwards running breath;
}
.controller .button {
    transform: scale(1);
    transform-origin: 50px 50px 0;
}
.controller.animate .button.btn-a, .controller.hover:hover .button.btn-a {
    animation: 1.2s linear -0.84s infinite normal forwards running breath;
}
.controller .button.btn-a circle {
    fill: rgb(248, 178, 106);
}
.controller.animate .button.btn-b, .controller.hover:hover .button.btn-b {
    animation: 1.2s linear -0.93s infinite normal forwards running breath;
}
.controller .button.btn-b circle {
    fill: rgb(119, 164, 189);
}
.controller.animate .button.btn-c, .controller.hover:hover .button.btn-c {
    animation: 1.2s linear -1.02s infinite normal forwards running breath;
}
.controller .button.btn-c circle {
    fill: rgb(132, 155, 135);
}
.controller.animate .button.btn-d, .controller.hover:hover .button.btn-d {
    animation: 1.2s linear -1.2s infinite normal forwards running breath;
}
.controller .button.btn-d circle {
    fill: rgb(244, 126, 96);
}
</style>