'use strict';

export default class GameSuggestEntity
{
    constructor(id, title, slug, mediums)
    {
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.mediums = mediums;
    }

    static fromObject(data)
    {
        return new this(data.id, data.title, data.slug, data.mediums);
    }
}