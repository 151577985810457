<template>
    <ul class="data">
        <li v-if="game.rating.counter > 0">
            <span class="title">Rating:</span>
            <span class="info">{{ game.rating.rating / 10 }} based on {{ game.rating.counter }} ratings</span>
        </li>
        <li v-if="releaseDate" class="date-small">
            <span class="title">Release date:</span>
            <span class="info">{{ releaseDate }}</span>
        </li>
        <li v-if="game.genres.length > 0">
            <span class="title">Genres:</span>
            <span class="info">
                <template v-for="item in game.genres" v-bind:key="item.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'genres': item.id}}">{{ item.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="game.platforms.length > 0">
            <span class="title">Platforms:</span>
            <span class="info">
                <template v-for="item in game.platforms" v-bind:key="item.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'platforms': item.id}}">{{ item.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="game.gameModes.length > 0">
            <span class="title">Game modes:</span>
            <span class="info">
                <template v-for="item in game.gameModes" v-bind:key="item.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'gameModes': item.id}}">{{ item.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="game.themes.length > 0">
            <span class="title">Themes:</span>
            <span class="info">
                <template v-for="item in game.themes" v-bind:key="item.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'themes': item.id}}">{{ item.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="game.playerPerspectives.length > 0">
            <span class="title">Player Perspectives:</span>
            <span class="info">
                <template v-for="item in game.playerPerspectives" v-bind:key="item.id">
                    <router-link v-bind:to="{name: 'game.overview', query: {'playerPerspectives': item.id}}">{{ item.title }}</router-link>
                </template>
            </span>
        </li>
        <li v-if="game.franchises.length > 0">
            <span class="title">Franchises:</span>
            <span class="info">
                <template v-for="item in game.franchises" v-bind:key="item.id">
                    <span>{{ item.title }}</span>
                </template>
            </span>
        </li>
        <li v-if="game.series.length > 0">
            <span class="title">Series:</span>
            <span class="info">
                <template v-for="item in game.series" v-bind:key="item.id">
                    <span>{{ item.title }}</span>
                </template>
            </span>
        </li>
        <li v-if="game.serviceURL">
            <span class="title">Service link:</span>
            <span class="info"><a v-bind:href="game.serviceURL" target="_blank">Link</a></span>
        </li>
        <li v-if="game.companies.length > 0">
            <span class="title">Involved companies:</span>
            <span class="info">
                <template v-for="item in game.companies" v-bind:key="item.title">
                    <span>{{ item.title }} as {{ item.role.title }}</span>
                </template>
            </span>
        </li>
        <li v-if="game.storyline">
            <span class="title">Storyline:</span>
            <span class="info">{{ game.storyline }}</span>
        </li>
        <li v-if="game.summary">
            <span class="title">Summary:</span>
            <span class="info">{{ game.summary }}</span>
        </li>
    </ul>
</template>

<script>
import {computed} from "vue";
import GameEntity from "../../../entities/game/game.entity";

export default {

    props: {
        game: {
            type: GameEntity
        }
    },

    setup(props) {

        const releaseDate = computed(() => {

            if (null === props.game.releaseDate) {
                return null;
            }

            const date = props.game.releaseDate.date;

            let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
            let mo = new Intl.DateTimeFormat('en', {month: 'long'}).format(date);
            let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
            return `${da} ${mo} ${ye}`;
        });

        return {
            releaseDate
        }
    }
}
</script>