<template>
    <section class="gallery" data-close="true" v-bind:class="{active: enabled}">
        <div class="close" data-close="true">
            <span class="icon-close" data-close="true"></span>
        </div>
        <div class="picture">

            <div v-if="current" class="current">

                <img v-bind:src="current.files.image.url " v-on:load="image.loaded" v-bind:class="[image.loading.value ? 'downloading' : 'loaded']" alt="" ref="img">
                <div v-if="image.loading.value" class="loader">
                    <cmp-logo v-bind:animated="true" v-bind:delayed="true"></cmp-logo>
                </div>
                <div v-if="!image.loading.value" v-on:click="gallery.previous" class="previous">
                    <i class="icon-arrow-left arrow"></i>
                </div>
                <div v-if="!image.loading.value" v-on:click="gallery.next" class="next">
                    <i class="icon-arrow-right arrow"></i>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {onMounted, onUnmounted, ref, watch} from 'vue';

export default {

    props: {
        current: {
            type: Object,
            default: null,
        },
        enabled: {
            type: Boolean,
            required: true,
        },
        media: {
            type: Array,
            required: true,
        }
    },

    components: {
        'cmp-logo': require('../logo-cmp.vue').default,
    },

    setup(props, {emit}) {

        const img = ref(null);

        const gallery = {

            next() {

                if (true === image.loading.value) {
                    return;
                }

                let index = props.media.findIndex(medium => medium === props.current);
                emit('current', props.media[index < props.media.length - 1 ? ++index : 0]);
            },

            previous() {

                if (true === image.loading.value) {
                    return;
                }

                let index = props.media.findIndex(medium => medium === props.current);
                emit('current', props.media[index > 0 ? --index : props.media.length - 1]);
            },

            eventHandler(e) {

                //Right arrow navigation
                if (39 === e.keyCode) {
                    return gallery.next();
                }

                //Left arrow navigation
                if (37 === e.keyCode) {
                    return gallery.previous();
                }

                //Escape key or close button
                if (27 === e.keyCode || e.target.dataset.close) {
                    emit('enabled', false);
                }
            }
        }

        const image = {

            loading: ref(true),

            loaded() {

                image.loading.value = false;
                resizer.update();
            },

            downloading() {
                image.loading.value = true;
            }
        }

        const resizer = {

            interval: null,

            resized() {

                clearInterval(resizer.interval);
                resizer.interval = setTimeout(resizer.update, 100);
            },

            update() {

                if (true === props.enabled) {

                    const container = document.querySelector('.picture');
                    const image = container.querySelector('img');
                    const maxHeight = window.innerHeight * 0.9;
                    const maxWidth = window.innerWidth * 0.9;

                    let width = image.naturalWidth;
                    let height = image.naturalHeight;

                    if (height > maxHeight) {
                        width /= height / maxHeight
                    }

                    if (width > maxWidth) {
                        width = maxWidth;
                    }

                    container.style.width = width + 'px';
                }
            }
        }

        watch(
            () => props.current,
            () => {
                image.downloading()
            }
        );

        onMounted(() => {

            document.body.addEventListener('keyup', gallery.eventHandler);
            document.body.addEventListener('click', gallery.eventHandler);
            window.addEventListener('resize', resizer.resized);
        });

        onUnmounted(() => {
            document.body.removeEventListener('keyup', gallery.eventHandler);
            document.body.removeEventListener('click', gallery.eventHandler);
            window.addEventListener('resize', resizer.resized);
        });

        return {
            gallery,
            image,
            img,
        }
    }
}
</script>

<style scoped>
.gallery {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    z-index: 10;
    display: none;
}
.gallery.active {
    display: block;
    z-index: 10;
}
.gallery .picture {
    width: 90%;
    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.2s ease-in-out;
}
.gallery .current {
    background: #fff;
}
.gallery .current img {
    opacity: 0.4;
    width: 100%;
    height: auto;
    display: block;
    transition: opacity .3s ease-in-out;
}
.gallery .current img.downloading {
    opacity: 0.4;
}
.gallery .current img.loaded {
    opacity: 1;
}
.gallery .close span {
    z-index: 3;
    position: fixed;
    right: 25px;
    top: 25px;
    cursor: pointer;
}
.gallery .close span {
    color: #b7b7b7;
    font-size: 25px;
}
.gallery .close:hover span {
    color: #fff;
}
.gallery .next {
    right: 25px;
}
.gallery .previous {
    left: 25px;
}
.gallery .next, .gallery .previous {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;
    font-size: 40px;
    cursor: pointer;
}
.gallery .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 200px;
}
.gallery .arrow {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    font-size: 60px;
    color: #fff;
    display: none;
}
.gallery .previous {
    left: 0;
}
.gallery .next {
    right: 0;
}
.gallery .next:hover, .gallery .previous:hover {
    background: rgba(255, 255, 255, 0.3);
}
.gallery .next:hover .arrow, .gallery .previous:hover .arrow {
    display: block;
}
</style>
