'use strict';

export default class PlatformEntity
{
    constructor(id, title, slug, image)
    {
        this.id = parseInt(id);
        this.title = title;
        this.slug = slug;
        this.image = image;
    }

    static fromObject(data)
    {
        return new this(data.id, data.title, data.slug, process.env.VUE_APP_API_DOMAIN + data.image);
    }
}