<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">
            <div class="breadcrumb">
                <router-link v-bind:to="{name: 'game.overview'}">Home</router-link> / <router-link v-bind:to="{name: 'wishlist.overview'}">Wishlist overview</router-link> / Add game to collection
            </div>

            <cmp-medium-form v-if="model"
                 v-on:save="save"
                 v-bind:platform="model.platform.id"
                 v-bind:edition="model.edition.id"
                 v-bind:medium-type="model.mediumType.id"
                 v-bind:title="model.game.title">
            </cmp-medium-form>
        </div>
    </div>

    <cmp-footer></cmp-footer>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
import { useStore } from 'vuex';
import APIService from '../../services/api/api.service';
import NotificationEntity from '../../entities/notification.entity';

export default {

    emits: ['loading'],

    components: {

        'cmp-medium-form': require('../../components/game/medium-cmp').default,
        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
    },

    setup(props, { emit }) {

        const api = new APIService();
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const model = ref(null);

        const fetch = () => {

            let id = 'wishlist-fetch';

            emit('loading', id)

            api.wishlist.get(route.params.id)
                .then(response => {
                    model.value = response.item;
                })
                .catch(error => {

                    if(401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                })
                .finally(() => {
                    emit('loading', id);
                });
        }

        const save = medium => {

            let id = 'wishlist-save';

            emit('loading', id);

            medium.game = model.value.game.id;

            api.gameMedium.add(medium)
                .then(async () => {

                    const wishlist = {
                        isOwned: true,
                        game: model.value.game.id,
                        platform: model.value.platform.id,
                        edition: model.value.edition.id,
                        mediumType: model.value.mediumType.id,
                        priority: model.value.priority.priority
                    };

                    await api.wishlist.update(model.value.id, wishlist);
                    await store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully moved game from wishlist to collection.'));

                    emit('loading', id);

                    await router.push({name: 'game.detail', params: {id: model.value.game.id, slug: model.value.game.slug}});

                })
                .catch(error => {

                    if (401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error has occurred.'));
                });
        }

        fetch();

        return {
            save,
            model
        }
    }
};
</script>