export default {

    namespaced: true,

    state : () => ({
        items : [],
        interval: null,
    }),

    mutations : {

        add(state, notification) {

            state.items.push(notification);

            //Auto remove notification after 3 seconds
            clearTimeout(state.interval);

            state.interval = setTimeout(() => {
                state.items.forEach((notification, index) => {
                    if (notification.timestamp + 3000 < new Date().getTime()) {
                        state.items.splice(index, 1);
                    }
                });
            }, 5000);
        }
    },

    actions : {

        add({commit}, notification) {
            commit('add', notification);
        }
    }
}