'use strict';

import { DateTimeEntity } from './date-time.entity';

export default class GameEntity
{
    constructor(id, title, slug)
    {
        this.id = parseInt(id);
        this.title = title;
        this.slug = slug;
        this.cover = null;
        this.releaseDate = null;
        this.platforms = [];
    }

    static fromObject(data)
    {
        const game = new this(data.id, data.title, data.slug);

        if(false === isNaN(data.releaseDate)) {
            game.releaseDate = DateTimeEntity.fromTimestamp(Date.parse(data.releaseDate));
        }

        if(undefined !== data.platforms) {
            game.platforms = data.platforms;
        }

        //Create cover data
        game. cover = data.cover;

        return game;
    }
}