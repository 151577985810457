import { createRouter, createWebHistory } from 'vue-router';
import game from '../views/game/index';
import wishlist from '../views/wishlist/index';
import account from '../views/account/index';
import store from "../store/index";

const routes = [
    {
        path: '/:page?',
        name: 'game.overview',
        component: game.overview
    },
    {
        path: '/games/:id/:slug',
        name: 'game.detail',
        component: game.view
    },
    {
        path: '/games/add',
        name: 'game.add',
        component: game.add
    },
    {
        path: '/wishlist:page?',
        name: 'wishlist.overview',
        component: wishlist.overview
    },
    {
        path: '/wishlist/add/:id',
        name: 'wishlist.add',
        component: wishlist.add
    },
    {
        path: '/wishlist/edit/:id',
        name: 'wishlist.edit',
        component: wishlist.edit
    },
    {
        path: '/account/platforms',
        name: 'account.platforms',
        component: account.platforms
    },
];

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {

        const position = savedPosition ?? {top: 0, left: 0};
        store.dispatch('document/scroll', position)
        return position;
    },
});
