<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">
            <div class="breadcrumb">
                <router-link v-bind:to="{name: 'game.overview'}">Home</router-link> / <router-link v-bind:to="{name: 'wishlist.overview'}">Wishlist overview</router-link> / Edit wishlist item
            </div>

            <cmp-wishlist-form v-if="model"
                 v-on:save="save"
                 v-bind:title="model.game.title"
                 v-bind:platform="model.platform.id"
                 v-bind:edition="model.edition.id"
                 v-bind:medium-type="model.mediumType.id"
                 v-bind:priority="model.priority.priority">
            </cmp-wishlist-form>
        </div>
    </div>

    <cmp-footer></cmp-footer>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref } from 'vue';
import { useStore } from 'vuex';
import APIService from '../../services/api/api.service';
import NotificationEntity from '../../entities/notification.entity';

export default {

    emits: ['loading'],

    components: {

        'cmp-wishlist-form': require('../../components/game/wishlist-cmp').default,
        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
    },

    setup(props, { emit }) {

        const api = new APIService();
        const store = useStore();
        const route = useRoute();
        const model = ref(null);

        const fetch = () => {

            let id = 'fetch-wishlist';

            emit('loading', id)

            api.wishlist.get(route.params.id)
                .then(response => {
                    model.value = response.item;
                })
                .catch(error => {

                    if(401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                })
                .finally(() => {
                    emit('loading', id);
                });
        }

        const save = wishlist => {

            let loadingId = 'wishlist-save';

            emit('loading', loadingId);

            wishlist.game = model.value.game.id;

            api.wishlist.update(route.params.id, wishlist)
                .then(async () => {

                    await store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully updated wishlist item.'));
                    emit('loading', loadingId);
                })
                .catch(error => {

                    if (401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error has occurred.'));
                });
        }

        fetch();

        return {
            save,
            model,
        }
    }
};
</script>