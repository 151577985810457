<template>
    <cmp-header></cmp-header>

    <div class="main-content">
        <div class="wrapper">
            <div class="header">
                <div class="buttons">
                    <input type="button" class="btn" v-bind:value="'Filters' + (filter.active.value > 0 ? ' (' + filter.active.value + ')' : '')" v-on:click="filter.open">
                    <input type="button" class="btn" value="Options" v-on:click="options.open">
                </div>

                <cmp-options v-bind:selected="selected.options" v-on:update="options.update" v-on:close="options.close" v-bind:enabled="options.enabled.value"></cmp-options>
            </div>

            <div class="overview">

                <cmp-filters v-bind:selected="selected.filters" v-bind:aggregations="aggregations" v-on:update="filter.update" v-on:close="filter.close" v-bind:enabled="filter.enabled.value"></cmp-filters>

                <div class="item-container">
                    <p class="zero-items" v-if="items.length === 0 && true === status.loaded.value">Sorry, no games found :(</p>

                    <div class="items" v-if="items.length > 0" >
                        <template  v-for="item in items" v-bind:key="item.id">
                            <router-link v-bind:to="{ name: 'game.detail', params: { id: item.game.id, slug: item.game.slug }, hash: '#' + item.id }" class="item">
                                <div class="image">
                                    <img class="placeholder-cover" src="/images/placeholder-cover.png" alt="">
                                    <img v-if="item.game.images?.cover?.files?.thumbnail" v-bind:src="item.game.images.cover.files.thumbnail.url" alt="" class="cover">
                                    <img v-else src="/images/no-cover.png" alt="" class="cover">

                                    <div class="platform-title">
                                        {{ item.platform.title }}
                                    </div>
                                </div>
                                <span class="title">{{ item.game.title }}</span>

                            </router-link>
                        </template>
                    </div>

                    <cmp-pagination v-bind:page="page"></cmp-pagination>
                </div>
            </div>
        </div>
    </div>

    <cmp-footer></cmp-footer>
</template>

<script>
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import {computed, onMounted, reactive, ref} from 'vue';
import { useStore } from 'vuex';
import { usePage } from '../../library/pagination';
import { DEFAULT_OVERVIEW_SORT, DEFAULT_OVERVIEW_ORDER, DEFAULT_OVERVIEW_LIMIT } from '@/constants';
import APIService from '../../services/api/api.service';
import NotificationEntity from '../../entities/notification.entity';
import {scroll} from '../../library/scroll';

export default {

    emits: ['loading'],

    components: {

        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
        'cmp-pagination': require('../../components/pagination/pagination-cmp.vue').default,
        'cmp-filters': require('../../components/overview/filters-cmp.vue').default,
        'cmp-options': require('../../components/overview/options-cmp.vue').default,
    },

    setup(props, {emit}) {

        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const items = ref([]);
        const aggregations = ref({});
        const api = new APIService();
        const page = reactive(usePage(parseInt(route.query.page) > 0 ? route.query.page : 1, DEFAULT_OVERVIEW_LIMIT));

        const status = {
            loaded: ref(false)
        }

        const selected = reactive({

            filters: {

                genres: {filters: [], included: true, multiple: true},
                playerPerspectives: {filters: [], included: true, multiple: true},
                gameModes: {filters: [], included: true, multiple: true},
                themes: {filters: [], included: true, multiple: true},
                platforms: {filters: [], included: true, multiple: true},
                regions: {filters: [], included: true, multiple: true},
                languages: {filters: [], included: true, multiple: true},
                properties: {filters: [], included: true, multiple: true},
                mediumTypes: {filters: [], included: true, multiple: true},
                editions: {filters: [], included: true, multiple: true},
                launchers: {filters: [], included: true, multiple: true},
                defects: {filters: [], included: true, multiple: true},
                conditions: {filters: [], included: true, multiple: true},
                seals: {filters: [], included: true, multiple: false},
            },

            options: {
                sort: DEFAULT_OVERVIEW_SORT,
                order: DEFAULT_OVERVIEW_ORDER,
                limit: page.limit,
            },

            title: null,

            fill(route) {

                selected.options.sort = DEFAULT_OVERVIEW_SORT;
                selected.options.order = DEFAULT_OVERVIEW_ORDER;
                selected.options.limit = route.query.limit || page.limit;

                for(const type in selected.filters) {
                    selected.filters[type].filters = [];
                    selected.filters[type].included = true;
                }

                for(const type in route.query) {

                    if(undefined !== selected.filters[type]) {

                        if(false === selected.filters[type].multiple) {
                            selected.filters[type].filters = route.query[type];
                        }
                        else {
                            selected.filters[type].filters = (Array.isArray(route.query[type]) ? route.query[type] : [route.query[type]]);
                        }
                    }

                    if(undefined !== selected.options[type]) {
                        selected.options[type] = route.query[type];
                    }
                }

                if(undefined !== route.query.excluded) {

                    const excluded = (Array.isArray(route.query.excluded) ? route.query.excluded : [route.query.excluded]);

                    excluded.forEach(type => {

                        if(undefined !== selected.filters[type]) {
                            selected.filters[type].included = false;
                        }
                    });
                }

                selected.title = route.query.title;
            },

            getQuery() {

                const query = {
                    page : page.current,
                    excluded: []
                };

                //Filters
                for(const type in selected.filters) {

                    if(0 === selected.filters[type].length) {
                        continue;
                    }

                    query[type] = Array.isArray(selected.filters[type].filters) ? selected.filters[type].filters.map(item => parseInt(item)) : parseInt(selected.filters[type].filters);

                    const excluded = selected.filters[type].included;

                    if(false === excluded) {
                        query.excluded.push(type);
                    }
                }

                //Options
                for(const type in selected.options) {
                    query[type] = selected.options[type];
                }

                //Title
                query.title = selected.title;

                //Page
                query.limit = parseInt(selected.options.limit);

                return query;
            }
        });

        const options = {

            enabled: ref(false),

            open() {
                options.enabled.value = true;
            },

            close() {
                options.enabled.value = false;
            },

            update(items) {

                for(const type in items) {

                    if(undefined !== selected.options[type]) {
                        selected.options[type] = items[type];
                    }
                }

                page.current = 1;

                router.push({
                    name: route.name,
                    query: selected.getQuery()
                });
            }
        };

        const filter = {

            enabled: ref(false),

            open() {
                filter.enabled.value = true;
            },

            close() {
                filter.enabled.value = false;
            },

            update(items) {

                selected.filters = items;
                page.current = 1;

                router.push({
                     name: route.name,
                     query: selected.getQuery()
                });
            },

            active: computed(() => {

                let amount = 0;

                for(const type in selected.filters) {
                    amount += selected.filters[type].filters.length;
                }

                return amount;
            }),
        }

        const fetch = () => {

            let id = 'overview-fetch';

            emit('loading', id);

            api.gameMedium.list(selected.getQuery())
                .then(response => {

                    page.fromObject(response.pagination);
                    items.value = response.items;
                    aggregations.value = response.aggregations.aggregations;
                    status.loaded.value = true;
                    scroll(store);
                })
                .catch(error => {

                    if(401 === error.status) {
                        store.dispatch('account/logout');
                        return;
                    }

                    store.dispatch('notifications/add', new NotificationEntity('Error', 'error', 'An unknown error occurred.'));
                })
                .finally(() => {
                    emit('loading', id);
                });
        }

        onBeforeRouteUpdate((to, from) => {

            if(to.name === from.name) {

                page.current = (to.query.page || 0) > 0 ? to.query.page : 1;
                selected.fill(to);
                fetch();
            }
        });

        onMounted(() => {

            selected.fill(route);
            fetch();
        });

        return {
            page,
            items,
            filter,
            route,
            options,
            selected,
            status,
            aggregations
        }
    }
};
</script>

<style>
/* Other */
.overview {
    display: flex;
}
h3 {
    color: #333;
    letter-spacing: 1px;
    text-transform: capitalize;
    text-align: left;
    font-size: 14px;
}
.zero-items {
    text-align: center;
    width: 100%;
    margin: 20px 0;
}


/* Header */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 20px 20px;
}
.header .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.header .buttons .btn {
    min-width: 105px;
}
@media screen and (min-width: 750px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px 20px 0;
    }
    .header .buttons {
        display: none;
    }
    .panel-container .panel-title {
        margin-top: 50px;
    }
    .panel-container .panel-title:first-of-type {
        margin-top: 18px;
    }
}


/* Panels */
.panel-container .close {
    display: none;
}
@media screen and (max-width: 749px) {
    .panel-container {
        margin: 0;
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 2;
    }
    .panel-left {
        left: -100%;
        transition: left .3s ease-in-out;
    }
    .panel-right {
        right: -100%;
        transition: right .3s ease-in-out;
    }
    .panel-container.panel-left.active {
        left: 0;
    }
    .panel-container.panel-right.active {
        right: 0;
    }
    .panel-container .background {
        visibility: hidden;
        margin: 0;
        position: fixed;
        background: #000;
        opacity: 0;
        left: 0;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 1;
        transition: opacity .3s ease-in-out;
    }
    .panel-container.active .background {
        visibility: initial;
        opacity: 0.5;
    }
    .panel-container .content {
        background: #fff;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        box-shadow: 0 0 20px 10px rgba(0, 0, 0, .5);
        width: 75%;
        z-index: 2;
    }
    .panel-container .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
    }
    .panel-container .panel-title:first-of-type {
        margin-top: 0;
        padding-top: 20px;
    }
    .panel-container .options .panel-title {
        margin: 0 0 20px 0;
    }
    .panel-container .close {
        display: block;
        cursor: pointer;
    }
}
@media screen and (max-width: 320px) {
    .panel-container .content {
        width: 100%;
        box-shadow: none;
    }
}

.platform-title {
    background: rgba(20,20,20,.8);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    color: rgb(220, 220, 220);
}
</style>