import MediumDefectEntity from '../../entities/game/medium-defect.entity';

export default {

    namespaced: true,

    state : () => ({
        items : []
    }),

    mutations : {

        hydrate(state, defects) {
            state.items = defects;
        }
    },

    actions : {

        hydrate({commit}, items) {

            items.sort((a, b) => a.title.localeCompare(b.title));

            const property = items.map(item => {
                return MediumDefectEntity.fromObject(item);
            });

            commit('hydrate', property);
        }
    }
}