import LanguageEntity from '../../entities/game/language.entity';

export default {

    namespaced: true,

    state : () => ({
        items : []
    }),

    mutations : {

        hydrate(state, languages) {
            state.items = languages;
        }
    },

    actions : {

        hydrate({commit}, items) {

            items.sort((a, b) => a.title.localeCompare(b.title));

            const languages = items.map(item => {
                return LanguageEntity.fromObject(item);
            });

            commit('hydrate', languages);
        }
    }
}