'use strict';

export default class AggregationsEntity
{
    constructor()
    {
        this.aggregations = {};
    }

    static fromObject(aggregations)
    {
        const instance = new this();

        for(const filter in aggregations) {

            instance.aggregations[filter] = {};

            for(const aggregation of aggregations[filter]) {
                instance.aggregations[filter][aggregation.id] = aggregation.amount
            }
        }

        return instance;
    }
}