<template>
    <cmp-header></cmp-header>

    <div class="main-content game-detail">
        <div class="wrapper">
            <div class="background-small-container">
                <div class="background-large-container">
                    <div v-if="backgroundImage" class="background" v-bind:style="{backgroundImage: 'url(' + backgroundImage + ')'}"></div>
                    <div v-else class="background no-background"></div>
                </div>
                <div class="game-info">
                    <div class="cover">
                        <img v-if="!loaded" class="placeholder-cover" src="/images/placeholder-cover.png" alt="">
                        <img v-else-if="!game.images" class="no-cover" src="/images/no-cover.png" alt="">
                        <img v-else-if="game.images?.cover?.files?.image" v-bind:src="game.images?.cover?.files?.image.url" alt="">
                        <img v-else class="no-cover" src="/images/no-cover.png" alt="">
                    </div>
                    <div class="detail" v-if="loaded">
                        <h2 class="title">{{ game.title }}</h2>
                        <h3 class="date-big">{{ releaseDate }}</h3>
                        <h4 class="company">{{ company.title }}</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-container">
                    <div class="tabs">
                        <ul class="tab-items slide-container" v-on:mousedown="slider.mouseDown" v-on:mouseup="slider.mouseUp" v-on:mousemove="slider.mouseMove" v-on:mouseleave="slider.mouseLeave">
                            <li class="tab-item" v-bind:class="{selected: null === tabs.selected.value && mode.state.value !== MODE_ADD, disabled: mode.state.value === MODE_EDIT || mode.state.value === MODE_ADD}">
                                <router-link v-bind:to="{ name: 'game.detail', params: { id, slug }}">
                                    <span>Overview</span>
                                </router-link>
                            </li>
                            <template v-for="(item, index) in gameMedia" v-bind:key="item.id">
                                <li class="tab-item" v-bind:data-hash="item.slug" v-bind:data-index="index" v-bind:class="{selected: index === tabs.selected.value && mode.state.value !== MODE_ADD, disabled: mode.state.value === MODE_EDIT || mode.state.value === MODE_ADD}">
                                    <router-link v-bind:to="{ name: 'game.detail', params: { id, slug }, hash: '#' + item.id}" draggable="false">
                                        <span>{{ item.platform.title }}</span>
                                    </router-link>
                                </li>
                            </template>
                        </ul>
                        <div class="options" v-bind:class="{'active' : options.enabled.value, icons: mode.state.value === MODE_EDIT || mode.state.value === MODE_ADD}">
                            <template v-if="mode.state.value === MODE_EDIT || mode.state.value === MODE_ADD">
                                <i class="icon-close" v-on:click="medium.cancel"></i>
                            </template>
                            <template v-else>
                                <i class="icon-ellipsis-vertical" v-on:click="options.toggle"></i>
                                <ul v-if="options.enabled.value" class="menu" v-on:click="options.toggle">
                                    <template v-if="null !== tabs.selected.value">
                                        <li><a v-on:click="mode.change(MODE_EDIT)">Edit medium</a></li>
                                        <li><a v-on:click="medium.remove">Delete medium</a></li>
                                    </template>
                                    <template v-else>
                                        <li><a v-on:click="add">Add medium</a></li>
                                        <li><a v-on:click="modal.game.open">Delete game</a></li>
                                        <li v-if="user.roles.includes('admin')"><a v-on:click="refresh">Refresh game info</a></li>
                                    </template>
                                </ul>
                            </template>
                        </div>
                    </div>

                    <div class="tab-slides" v-if="loaded">

                        <div v-if="mode.state.value === MODE_ADD">
                            <cmp-medium-form class="data" v-on:save="medium.add"></cmp-medium-form>
                        </div>
                        <template v-else>

                            <div v-if="null === tabs.selected.value">
                                <cmp-game-detail v-bind:game="game"></cmp-game-detail>
                            </div>

                            <template v-for="(item, index) in gameMedia" v-bind:key="item.id">

                                <div v-if="index === tabs.selected.value">
                                    <cmp-medium-form v-if="mode.state.value === MODE_EDIT"
                                        class="data"
                                        v-on:save="medium.save"
                                        v-bind:platform="item.platform.id"
                                        v-bind:region="item.region.id"
                                        v-bind:edition="item.edition.id"
                                        v-bind:launcher="item.launcher?.id"
                                        v-bind:condition="item.condition.condition"
                                        v-bind:medium-type="item.type.id"
                                        v-bind:is-sealed="item.sealed"
                                        v-bind:notes="item.notes"
                                        v-bind:languages="item.languages.map(language => language.id)"
                                        v-bind:properties="item.properties.map(property => property.id)"
                                        v-bind:defects="item.defects.map(property => property.id)">
                                    </cmp-medium-form>

                                    <cmp-medium-detail v-else v-bind:item="item"></cmp-medium-detail>

                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <cmp-carousel v-if="loaded && media.length > 0" v-bind:media="media"></cmp-carousel>

        <cmp-recommendations v-if="loaded" v-bind:id="id"></cmp-recommendations>

    </div>

    <cmp-footer></cmp-footer>

    <cmp-modal v-bind:enabled="modal.game.enabled" v-on:close="modal.game.close">
        <template v-slot:content>Are you sure you want to delete the selected game?</template>
        <template v-slot:actions>
            <button v-on:click="modal.game.remove" class="btn">Remove game</button>
        </template>
    </cmp-modal>

    <cmp-modal v-bind:enabled="modal.medium.enabled" v-on:close="modal.medium.close">
        <template v-slot:content>Are you sure you want to delete the selected medium?</template>
        <template v-slot:actions>
            <button v-on:click="modal.medium.remove" class="btn">Remove medium</button>
        </template>
    </cmp-modal>
</template>

<script>
import {ref, computed, reactive, onMounted, onUnmounted, inject, watch} from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter} from 'vue-router';
import { useStore } from 'vuex';
import APIService from '../../services/api/api.service';
import NotificationEntity from '../../entities/notification.entity';

const MODE_EDIT = 'edit';
const MODE_VIEW = 'view';
const MODE_ADD = 'add';

export default {

    emits: ['loading'],

    components: {

        'cmp-carousel': require('../../components/media/carousel-cmp.vue').default,
        'cmp-header': require('../../components/header-cmp.vue').default,
        'cmp-footer': require('../../components/footer-cmp.vue').default,
        'cmp-modal': require('../../components/modal/remove-cmp.vue').default,
        'cmp-medium-form': require('../../components/game/medium-cmp').default,
        'cmp-game-detail': require('../../components/game/view/details-cmp').default,
        'cmp-medium-detail': require('../../components/medium/view/details-cmp.vue').default,
        'cmp-recommendations': require('../../components/game/recommendations-cmp.vue').default,

    },

    setup(props, { emit }) {

        const store = useStore();
        const user = inject('account').user();
        const loaded = ref(false);
        const gameMedia = ref({});
        const game = ref({});
        const router = useRouter();
        const route = useRoute();
        const api = new APIService();
        const mode = {

            state: ref(MODE_VIEW),

            change(state) {

                if(state === MODE_EDIT) {
                    tabs.selected.value = tabs.selected.value > 0 ? tabs.selected.value : 0;
                }

                mode.state.value = state;
            }
        };
        const id = ref(route.params.id);
        const slug = ref(route.params.slug);

        const medium = {

            add(medium) {

                let loadingId = 'game-view';

                medium.game = game.value.id;
                emit('loading', loadingId);

                api.gameMedium.add(medium).then(response => {

                    gameMedia.value.push(response.item);
                    store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully added new medium.'));
                    router.push({name: 'game.detail', params: { id: id.value, slug: slug.value }, hash: '#' + response.item.id});
                    mode.change(MODE_VIEW);
                })
                .catch(error => {

                    if(401 === error.status) {
                        return store.dispatch('account/logout');
                    }
                })
                .finally(() => {
                    emit('loading', loadingId);
                });
            },

            save(medium) {

                let loadingId = 'medium-save';

                const id = gameMedia.value[tabs.selected.value].id;
                medium.game = game.value.id;
                emit('loading', loadingId);

                api.gameMedium.update(id, medium).then(response => {

                    game.value.gameMedia[tabs.selected.value] = response.item;
                    store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully updated medium.'));
                    mode.change(MODE_VIEW);
                })
                .catch(error => {

                    if(401 === error.status) {
                        return store.dispatch('account/logout');
                    }
                })
                .finally(() => {
                    emit('loading', loadingId);
                });
            },

            cancel() {
                mode.change(MODE_VIEW);
            },

            remove() {
                modal.medium.open();
            }
        }

        const item = {

            fetch(id) {

                api.game.fetch(id).then(response => {

                    game.value = response.item;
                    gameMedia.value = game.value.gameMedia;
                    loaded.value = true;

                    tabs.hash();
                })
                .catch(error => {

                    if(401 === error.status) {
                        return store.dispatch('account/logout');
                    }
                });
            }
        };

        const backgroundImage = computed(() => {

            if (false === loaded.value) {
                return null;
            }

            const screenshots = game.value.images.screenshots;

            if(screenshots.length > 0) {
                return screenshots[Math.floor(Math.random() * screenshots.length)].files.image.url;
            }

            return null;
        });

        const company = computed(() => {

            if (false === loaded.value) {
                return null;
            }

            const developer = game.value.companies.find(company => 'developer' === company.role);
            const publisher = game.value.companies.find(company => 'publisher' === company.role);
            const porting = game.value.companies.find(company => 'porting' === company.role);
            const supporter = game.value.companies.find(company => 'supporter' === company.role);

            return developer || publisher || porting || supporter || {};
        });

        const media = computed(() => {

            if (false === loaded.value) {
                return [];
            }

            return [].concat(game.value.images.screenshots).concat(game.value.images.artwork);
        });

        const slider = {

            isDown: false,
            startX: null,
            scrollLeft: null,
            container: null,
            acceleration: 1,

            mouseDown(e) {

                slider.isDown = true;
                slider.container = e.target.closest('.slide-container');
                slider.startX = e.pageX - slider.container.offsetLeft;
                slider.scrollLeft = slider.container.scrollLeft;
            },

            captureClick(e) {

                e.stopPropagation();
                window.removeEventListener('click', slider.captureClick, true);
            },

            mouseLeave() {
                slider.isDown = false;
            },

            mouseUp() {
                slider.isDown = false;
            },

            mouseMove(e) {

                if(false === slider.isDown){
                    return;
                }

                window.addEventListener('click', slider.captureClick,true);

                e.preventDefault();
                const x = e.pageX - slider.container.offsetLeft;
                const walk = (x - slider.startX) * slider.acceleration;
                slider.container.scrollLeft = slider.scrollLeft - walk;
            },
        }

        const releaseDate = computed(() => {

            if (null === game.value.releaseDate) {
                return null;
            }

            const date = game.value.releaseDate.date;

            let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
            let mo = new Intl.DateTimeFormat('en', {month: 'long'}).format(date);
            let da = new Intl.DateTimeFormat('en', {day: 'numeric'}).format(date);
            return `${da} ${mo} ${ye}`;
        });

        const tabs = {

            selected: ref(null),

            select(index) {

                if(mode.state.value === MODE_EDIT || mode.state.value === MODE_ADD) {
                    return;
                }

                tabs.selected.value = index;
            },

            // Selects the current tab from the url/route hash
            hash() {

                const hash = route.hash && route.hash.replace('#', '');

                for(const index in gameMedia.value) {

                    if(hash !== gameMedia.value[index].id) {
                        continue;
                    }

                    tabs.select(parseInt(index));
                    return;
                }

                tabs.select(null);
            }
        }

        const options = {

            enabled: ref(false),

            toggle() {
                options.enabled.value = !options.enabled.value;
            },

            close(e) {

                if(undefined !== e && null !== e.target.closest('.options')) {
                    return;
                }

                options.enabled.value = false;
            }
        }

        const modal = reactive({

            game: {

                enabled: false,

                open() {
                    modal.game.enabled = true;
                },

                close: () => {
                    modal.game.enabled = false;
                },

                async remove() {

                    let loadingId = 'game-remove';
                    emit('loading', loadingId);

                    const id = route.params.id;

                    await Promise.all(game.value.gameMedia.map(async medium => {
                        await api.gameMedium.remove(medium.id);
                    }));

                    api.game.remove(id).then(() => {
                        store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully deleted game.'));
                        router.back();
                    })
                    .catch(error => {

                        if(401 === error.status) {
                            return store.dispatch('account/logout');
                        }
                    }).finally(() => {

                        emit('loading', loadingId);
                        modal.game.close();
                    });
                }
            },

            medium: {

                enabled: false,

                open() {
                    modal.medium.enabled = true;
                },

                close: () => {
                    modal.medium.enabled = false;
                },

                async remove() {

                    let loadingId = 'medium-remove';

                    emit('loading', loadingId);

                    const mediumId = gameMedia.value[tabs.selected.value].id;

                    api.gameMedium
                        .remove(mediumId)
                        .then(() => {

                            store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Successfully deleted medium.'));
                            gameMedia.value.splice(tabs.selected.value, 1);
                            router.push({name: 'game.detail', params: { id: id.value, slug: slug.value }, hash: ''});
                        })
                        .catch(error => {

                            if(401 === error.status) {
                                return store.dispatch('account/logout');
                            }
                        })
                        .finally(() => {

                            emit('loading', loadingId);
                            modal.medium.close();
                        });
                }
            }
        });

        const add = () => {
            mode.change(MODE_ADD);
        }

        const refresh = () => {

            let loadingId = 'game-refresh';
            emit('loading', loadingId);

            api.game.refresh(route.params.id).then(() => {
                store.dispatch('notifications/add', new NotificationEntity('Success', 'success', 'Game information is queued to refresh.'));
            })
            .catch(error => {
                401 === error.status && store.dispatch('account/logout');
            })
            .finally(() => {
                emit('loading', loadingId);
            });
        }

        onMounted(() => {
            document.body.addEventListener('click', options.close);
        });

        onUnmounted(() => {
            document.body.removeEventListener('click', options.close);
        });

        onBeforeRouteUpdate((to, from) => {

            if(from.params.id === to.params.id) {

                setTimeout(() => {
                    tabs.hash();
                }, 100);
                return;
            }

            id.value = to.params.id;
            slug.value = to.params.slug;
            item.fetch(id.value);
        });

        item.fetch(route.params.id);

        return {
            loaded,
            company,
            gameMedia,
            backgroundImage,
            game,
            media,
            slider,
            tabs,
            options,
            modal,
            mode,
            medium,
            MODE_EDIT,
            MODE_VIEW,
            MODE_ADD,
            releaseDate,
            user,
            add,
            refresh,
            id,
            slug
        }
    }
};

</script>

<style scoped>
.game-detail .wrapper {
    padding: 0;
}

@media screen and (min-width: 750px) {
    .game-detail .wrapper {
        max-width: initial;
    }
    .game-detail .game-info {
        max-width: var(--max-width);
        margin: 70px auto 0 auto;
        padding: 0 3%;
    }
    .game-detail .background {
        max-height: 300px;
    }
    .game-detail .content-container {
        z-index: 4;
        position: relative;
        max-width: var(--max-width);
        padding: 0 3%;
        margin: auto;
    }
}

/* Background images */
.game-detail .background {
    background-position: 50% 50%;
    background-size: cover;
    filter: blur(8px);
    transform: matrix(1.1, 0, 0, 1.1, 0, 0);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}
.game-detail .background.no-background {
    background-image: url('../../../public/images/no-background.png');
}
.game-detail .background-small-container {
    overflow: hidden;
    position: relative;
    margin-top: -25px;
    padding: 25px;
}
@media screen and (min-width: 750px) {
    .game-detail .background-large-container {
        height: 300px;
        overflow: hidden;
        width: 100%;
        inset: 0;
        position: relative;
    }
    .game-detail .background-small-container {
        padding: 0;
        overflow: initial;
    }
}


/* Covers */
.game-detail .game-info .cover {
    text-align: center;
    position: relative;
    z-index: 2;
}
.game-detail .game-info .cover img {
    height: 210px;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.5);
}
.game-detail .game-info .cover .no-cover {
    background-color: #fff;
}
@media screen and (min-width: 750px) {
    .game-detail .game-info .cover {
        min-height: 357px;
    }
    .game-detail .game-info .cover img {
        width: 264px;
        height: auto;
    }
}


/* Game information */
.game-detail .game-info {
    position: relative;
    z-index: 2;
}
.game-detail .game-info .detail {
    margin-top: 10px;
}
.game-detail .game-info .detail h3 {
    margin: 15px 0;
}
.game-detail .game-info .detail .title {
    color: #fff;
    font-size: clamp(1.8rem, 2.5vw, 4rem);
    text-align: center;
    text-shadow: 1px 1px 4px #000;
}
.game-detail .game-info .detail .date-big {
    display: none;
}
.game-detail .game-info .detail .company {
    display: none;
}
@media screen and (min-width: 750px) {
    .game-detail .game-info {
        position: absolute;
        top: 0;
        display: flex;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .game-detail .game-info .detail {
        margin: 20px 25px;
        width: 100%;
    }
    .game-detail .game-info .detail .title {
        font-size: clamp(2.5rem, 4vw, 3.3rem);
        text-align: left;
        line-height: 1.2;
    }
    .game-detail .game-info .detail .date-big {
        color: #ddd;
        font-size: clamp(1.7rem, 2.5vw, 2.0rem);
        opacity: .8;
        display: block;
        line-height: 1;
        text-shadow: 1px 1px 4px #000;
    }
    .game-detail .game-info .detail .company {
        color: #ddd;
        font-size: clamp(1.5rem, 2.0vw, 1.8rem);
        opacity: .8;
        display: block;
        line-height: 1;
        text-shadow: 1px 1px 4px #000;
    }
}


/* Options */
.options {
    background: #fff;
    height: 60px;
    box-shadow: inset 0 -3px 0 rgb(236, 236, 236);
    flex-grow: 1;
    display: flex;
    justify-content: end;
    position: relative;
}
.options i {
    display: block;
    padding: 0 20px;
    cursor: pointer;
    height: inherit;
    line-height: 60px;
    outline: none;
    width: 60px;
}
.options.active i {
    background: var(--theme-color);
    color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
    outline: none;
}
.options::before {
    content: '';
    z-index: 5;
    width: 50px;
    height: 56px;
    pointer-events: none;
    background-image: linear-gradient(to right, transparent, #fff 70%);
    position: absolute;
    right: 60px;
}
.options.icons::before {
    content: initial;
}
.options .menu {
    position: absolute;
    top: 58px;
    background: var(--theme-color);
    box-shadow: 0 3px 3px rgba(0, 0, 0, .2);
}
.options .menu a {
    padding: 12px 15px;
    color: #fff;
    display: block;
    white-space: nowrap;
}
.options .menu a:hover {
    background: var(--theme-hover-color);
}


/* Tabs */
.tabs {
    display: flex;
    justify-content: space-between;
}
.tab-items {
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    scrollbar-color: transparent transparent;
}
.tab-items::-webkit-scrollbar {
    visibility: hidden;
}
.tab-item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: #34373b;
    background: #fff;
    box-shadow: inset 0 -3px 0 rgb(236, 236, 236);
}
.tab-item:last-child {
    padding-right: 30px;
}
.tab-item span {
    display: block;
    line-height: 60px;
    padding: 0 20px;
    user-select: none;
}
.tab-item:hover span {
    text-decoration: underline;
}
.tab-item.disabled span {
    color: rgb(236, 236, 236);
}
.tab-item.disabled:hover span {
    text-decoration: none;
    cursor: initial;
}
.tab-item.selected span {
    box-shadow: inset 0 -3px 0 var(--theme-color);
    text-decoration: none;
    cursor: initial;
    color: var(--theme-color);
}
.tab-slides {
    margin-top: 10px;
    min-height: 120px;
}

@media screen and (min-width: 750px) {
    .game-detail .tabs {
        margin-left: 290px;
    }
    .game-detail .tab-slides {
        margin-left: 290px
    }
}
</style>