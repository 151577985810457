'use strict';

import EditionEntity from './edition.entity';
import PlatformEntity from './platform.entity';
import GameEntity from './game.entity';
import TypeEntity from './type.entity';
import {PriorityEntity} from './priority.entity';
import DateTimeEntity from "./date-time.entity";

export default class WishlistEntity
{
    constructor(id)
    {
        this.id = id;
        this.game = null;
        this.game = null;
        this.platform = null;
        this.edition = null;
        this.mediumType = null;
        this.priority = new PriorityEntity(3);
        this.added = null;
    }

    static fromObject(data)
    {
        const wishlist = new this(data.id);

        if(undefined !== data.priority) {
            wishlist.priority = new PriorityEntity(data.priority);
        }

        if(undefined !== data.game) {
            wishlist.game = GameEntity.fromObject(data.game);
        }

        if(undefined !== data.mediumType) {
            wishlist.mediumType = TypeEntity.fromObject(data.mediumType);
        }

        if(undefined !== data.edition) {
            wishlist.edition = EditionEntity.fromObject(data.edition);
        }

        if(undefined !== data.platform) {
            wishlist.platform = PlatformEntity.fromObject(data.platform);
        }

        if(null !== data.created_at) {
            wishlist.added = DateTimeEntity.fromTimestamp(Date.parse(data.created_at));
        }

        return wishlist;
    }
}