import AggregationsEntity from "@/entities/aggregations.entity";

export default class CollectionResponse
{
    constructor(status = 200, pagination = null, aggregations = [])
    {
        this.status = status;
        this.items = [];
        this.pagination = pagination
        this.aggregations = AggregationsEntity.fromObject(aggregations)
    }

    add(item)
    {
        this.items.push(item);
    }
}