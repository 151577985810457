'use strict';

import { PRIORITIES } from '../../constants';

export class PriorityEntity
{
    constructor(priority)
    {
        this.priority = parseInt(priority);
    }

    get description()
    {
        const description = PRIORITIES.find(item => this.priority === item.priority);
        return description.title;
    }
}