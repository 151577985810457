<template>
    <vee-form v-bind:ref="el => forms.medium.value = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.medium">
        <ul class="form">

            <!-- Game title -->
            <li v-if="title" class="form-group">
                <input type="text" v-bind:value="title" disabled class="input-label">
                <label>Game</label>
            </li>

            <!-- Platform -->
            <li class="form-group">
                <vee-field id="input-add-platform" name="platform" as="select" v-model="model.platform" class="input-label" autofocus required="required" title="" v-bind:class="{error: errors.platform}">
                    <option v-for="item in medium.platforms.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-add-platform">Platform</label>
                <vee-error-message name="platform" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Medium type -->
            <li class="form-group">
                <vee-field id="input-medium-type" name="medium-type" as="select" v-model="model.mediumType" class="input-label" required="required" title="" v-on:change="medium.switch()" v-bind:class="{error: errors['medium-type']}">
                    <option v-for="item in medium.mediumTypes.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-medium-type">Medium type</label>
                <vee-error-message name="medium-type" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Editions -->
            <li class="form-group">
                <vee-field id="input-edition" name="edition" as="select" v-model="model.edition" class="input-label" required="required" title="" v-bind:class="{error: errors.edition}">
                    <option v-for="item in medium.editions.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-edition">Edition</label>
                <vee-error-message name="edition" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Region -->
            <li class="form-group">
                <vee-field id="input-region" name="region" as="select" v-model="model.region" class="input-label" required="required" title="" v-bind:class="{error: errors.region}">
                    <option v-for="item in medium.regions.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-region">Region</label>
                <vee-error-message name="region" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Launchers -->
            <li class="form-group" v-show="MEDIUM_TYPE_DIGITAL === model.mediumType">
                <vee-field id="input-launcher" name="launcher" as="select" v-model="model.launcher" class="input-label" required="required" title="" v-bind:class="{error: errors.launcher}">
                    <option v-for="item in medium.launchers.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                </vee-field>
                <label for="input-launcher">Launcher</label>
                <vee-error-message name="launcher" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Condition -->
            <li class="form-group" v-show="MEDIUM_TYPE_PHYSICAL === model.mediumType">
                <vee-field id="input-condition" name="condition" as="select" v-model="model.condition" class="input-label" required="required" title="" v-bind:class="{error: errors.condition}">
                    <option v-for="item in CONDITIONS" v-bind:value="item.condition" v-bind:key="item.condition">{{ item.title }}</option>
                </vee-field>
                <label for="input-condition">Condition</label>
                <vee-error-message name="condition" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Sealed -->
            <li class="form-group" v-show="MEDIUM_TYPE_PHYSICAL === model.mediumType">
                <vee-field id="input-sealed" name="sealed" as="select" v-model="model.isSealed" class="input-label" required="required" title="" v-bind:class="{error: errors.sealed}">
                    <option v-bind:value="false">No</option>
                    <option v-bind:value="true">Yes</option>
                </vee-field>
                <label for="input-sealed">Factory sealed</label>
                <vee-error-message name="sealed" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <!-- Properties -->
            <li class="form-group" v-show="MEDIUM_TYPE_PHYSICAL === model.mediumType">
                <fieldset>
                    <legend>Includes:</legend>
                    <ul class="form">
                        <li v-for="(item, index) in model.properties.length" v-bind:key="item">
                            <vee-form v-bind:ref="el => forms.property = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.property">
                                <div class="form-group">
                                    <vee-field v-bind:id="'input-property-' + index" name="property" as="select" v-model="model.properties[index]" class="input-label" required="required" title="" v-bind:class="{error: errors.property}">
                                        <option v-for="item in medium.properties.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                                    </vee-field>
                                    <label v-bind:for="'input-property-' + index">Include</label>
                                    <vee-error-message name="property" v-slot="{ message }">
                                        <span class="active error-icon">!</span>
                                        <span class="active error-text">{{ message }}</span>
                                    </vee-error-message>
                                </div>
                                <div class="icons">
                                    <span v-if="index === model.properties.length - 1" v-on:click="property.add()" class="icon-bg icon-plus"></span>
                                    <span v-on:click="property.remove(index)" class="icon-bg icon-bin"></span>
                                </div>
                            </vee-form>
                        </li>
                    </ul>
                </fieldset>
            </li>

            <!-- Medium Defects -->
            <li class="form-group" v-show="MEDIUM_TYPE_PHYSICAL === model.mediumType">
                <fieldset>
                    <legend>Medium defects:</legend>
                    <ul class="form">
                        <li v-for="(item, index) in model.defects.length" v-bind:key="item">
                            <vee-form v-bind:ref="el => forms.defect = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.defect">
                                <div class="form-group">
                                    <vee-field v-bind:id="'input-defect-' + index" name="defect" as="select" v-model="model.defects[index]" class="input-label" required="required" title="" v-bind:class="{error: errors.defect}">
                                        <option v-for="item in medium.defects.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                                    </vee-field>
                                    <label v-bind:for="'input-property-' + index">Defect</label>
                                    <vee-error-message name="property" v-slot="{ message }">
                                        <span class="active error-icon">!</span>
                                        <span class="active error-text">{{ message }}</span>
                                    </vee-error-message>
                                </div>
                                <div class="icons">
                                    <span v-if="index === model.defects.length - 1" v-on:click="defect.add()" class="icon-bg icon-plus"></span>
                                    <span v-on:click="defect.remove(index)" class="icon-bg icon-bin"></span>
                                </div>
                            </vee-form>
                        </li>
                    </ul>
                </fieldset>
            </li>

            <!-- Languages -->
            <li class="form-group">
                <fieldset>
                    <legend>Languages:</legend>
                    <ul class="form">
                        <li v-for="(item, index) in model.languages.length" v-bind:key="item">
                            <vee-form v-bind:ref="el => forms.language = el" v-slot="{ errors }" v-on:submit="save" v-bind:validation-schema="forms.schemes.language">
                                <div class="form-group">
                                    <vee-field v-bind:id="'input-language-' + index" name="language" as="select" v-model="model.languages[index]" class="input-label" required="required" title="" v-bind:class="{error: errors.language}">
                                        <option v-for="item in medium.languages.value" v-bind:value="item.id" v-bind:key="item.id">{{ item.title }}</option>
                                    </vee-field>
                                    <label v-bind:for="'input-language-' + index">Language</label>
                                    <vee-error-message name="language" v-slot="{ message }">
                                        <span class="active error-icon">!</span>
                                        <span class="active error-text">{{ message }}</span>
                                    </vee-error-message>
                                </div>
                                <div class="icons">
                                    <span v-if="index === model.languages.length - 1" v-on:click="language.add()" class="icon-bg icon-plus"></span>
                                    <span v-on:click="language.remove(index)" class="icon-bg icon-bin"></span>
                                </div>
                            </vee-form>
                        </li>
                    </ul>
                </fieldset>
            </li>

            <!-- Notes -->
            <li class="form-group">
                <vee-field id="input-notes" name="notes" as="textarea" v-model="model.notes" class="input-label" required="required" title="" v-bind:class="{error: errors.notes}"></vee-field>
                <label for="input-notes">User notes</label>
                <vee-error-message name="notes" v-slot="{ message }">
                    <span class="active error-icon">!</span>
                    <span class="active error-text">{{ message }}</span>
                </vee-error-message>
            </li>

            <li class="align-right">
                <slot></slot>
                <input value="Save" v-on:click="save" type="button" class="btn">
            </li>
        </ul>
    </vee-form>
</template>

<script>
import { computed, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import { configure } from 'vee-validate';
import { CONDITIONS, MEDIUM_TYPE_PHYSICAL, MEDIUM_TYPE_DIGITAL } from '@/constants';
import NotificationEntity from '../../entities/notification.entity';
import * as yup from 'yup';

export default {

    emits: ['save'],

    props: {
        platform: {
            type: Number,
            default: null,
        },
        region: {
            type: Number,
            default: 1,
        },
        edition: {
            type: Number,
            default: 8,
        },
        launcher: {
            type: Number,
            default: null,
        },
        condition: {
            type: Number,
            default: 0,
        },
        mediumType: {
            type: Number,
            default: 1,
        },
        isSealed: {
            type: Boolean,
            default: null,
        },
        languages: {
            type: Array,
            default: () => {
                return [null]
            },
        },
        notes: {
            type: String,
            default: null,
        },
        properties: {
            type: Array,
            default: () => {
                return [null]
            },
        },
        defects: {
            type: Array,
            default: () => {
                return [null]
            },
        },
        title: {
            type: String,
            default: null,
        },
    },

    components: {

        'vee-form': require('vee-validate').Form,
        'vee-field': require('vee-validate').Field,
        'vee-error-message': require('vee-validate').ErrorMessage,
    },

    setup(props, { emit }) {

        configure({
            validateOnBlur: false
        });

        const store = useStore();

        const model = reactive({
            platform: props.platform,
            region: props.region,
            languages: props.languages,
            properties: props.properties.length > 0 ? props.properties : [null],
            defects: props.defects.length > 0 ? props.defects : [null],
            edition: props.edition,
            launcher: props.launcher,
            condition: props.condition,
            notes: props.notes,
            mediumType: props.mediumType,
            isSealed: props.isSealed,
        });

        const forms = {

            isValid() {

                const formCollection = [forms.language, forms.property, forms.defect, forms.medium.value].filter(forms => forms);

                return new Promise((resolve, reject) => {

                    Promise.all(formCollection.filter(form => form).map(form => form.validate())).then(response => {

                        const valid = response.filter(validation => validation.valid).length === formCollection.length;

                        if(true === valid) {
                            return resolve(true);
                        }

                        reject(false);
                    });
                });
            },

            schemes: {

                medium: yup.object().shape({
                    platform: yup.string().nullable().required('Platform is required'),
                    region: yup.string().nullable().required('Region is required'),
                    edition: yup.string().nullable().required('Edition is required'),
                    condition: yup.string().nullable().required('Condition is required'),
                    sealed: yup.string().nullable().when('medium-type', {
                        is: value => {
                            return parseInt(value) === MEDIUM_TYPE_PHYSICAL;
                        },
                        then: () => yup.string().nullable().required('Sealed is required')
                    }),
                    notes: yup.string().nullable(),
                    'medium-type': yup.string().nullable().required('Medium type is required'),
                }),

                language: yup.object().shape({
                    language: yup.string().nullable().required('Language is required'),
                }),

                property: yup.object().shape({}),

                defect: yup.object().shape({}),
            },

            language: ref(),
            property: ref(),
            defect: ref(),
            medium: ref()
        }

        const medium = {

            platforms: computed(() => store.state.platforms.items),
            regions: computed(() => store.state.regions.items),
            editions: computed(() => store.state.editions.items),
            launchers: computed(() => store.state.launchers.items),
            mediumTypes: computed(() => store.state['medium-types'].items),
            languages: computed(() => store.state.languages.items),
            properties: computed(() => store.state.properties.items),
            defects: computed(() => store.state.defects.items),

            switch() {

                model.condition = 0;
                model.isSealed = false;
            }
        }

        const language = {

            add() {
                model.languages.push(null);
            },

            remove(index) {

                const languages = model.languages;

                languages.splice(index, 1);

                if (0 === languages.length) {
                    language.add();
                }
            }
        }

        const property = {

            add() {
                model.properties.push(null);
            },

            remove(index) {

                const properties = model.properties;

                properties.splice(index, 1);

                if (0 === properties.length) {
                    property.add();
                }
            }
        }

        const defect = {

            add() {
                model.defects.push(null);
            },

            remove(index) {

                const defects = model.defects;

                defects.splice(index, 1);

                if (0 === defects.length) {
                    defect.add();
                }
            }
        }

        const save = () => {

            forms.isValid().then(() => {

                const entity = Object.assign({}, model);

                entity.properties = entity.properties.filter(property => property)
                entity.defects = entity.defects.filter(defect => defect)
                entity.condition = parseInt(entity.condition)
                emit('save', entity);
            })
            .catch(() => {
                store.dispatch('notifications/add', new NotificationEntity('Warning', 'warning', 'There where some validation errors.'));
            })
        }

        watch(() => props.platform, () => {
            model.platform = props.platform;
        });

        return {
            model,
            medium,
            language,
            property,
            defect,
            forms,
            MEDIUM_TYPE_PHYSICAL,
            MEDIUM_TYPE_DIGITAL,
            CONDITIONS,
            save,
        }
    }
}
</script>